import { AppDispatch, AppThunk } from '..';
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  User,
  createUserWithEmailAndPassword,
  getAuth,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword
} from 'firebase/auth';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createNewUser, fetchUserDetails, resetUserState } from './userSlice';
import { deleteUserAPI, getUserAPI } from 'api/user';
import { USER_ROLE } from 'interfaces/enum';
import moment from 'moment';

interface AuthState {
  user: SerializableUser | null;
  loading: boolean;
  error?: string;
  success?: string;
}

type Provider = 'google.com' | 'password' | null;

interface SerializableUser {
  uid: string;
  email: string | null;
  emailVerified: boolean;
  providerId?: Provider;
}

const extractSerializableUserData = (user: User): SerializableUser => {
  return {
    uid: user.uid,
    email: user.email || '',
    emailVerified: user.emailVerified ||
      moment(user.metadata.creationTime, 'ddd, DD MMM YYYY HH:mm:ss [GMT]')
        .isBefore(moment('11 July 2023 10:16:44 +0300', 'DD MMMM YYYY HH:mm:ss Z')),
    providerId: user.providerData[0].providerId as Provider,
  };
};

const initialState: AuthState = {
  user: null,
  loading: true,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<SerializableUser | null>) => {
      if (action.payload) {
        // Create a new serializable object
        state.user = action.payload;
      } else {
        state.user = null;
      }
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setAuthError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    setAuthSuccess(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = undefined;
      state.success = action.payload;
    }
  },
});

export const { setUser, setLoading, setAuthError, setAuthSuccess } = authSlice.actions;

const personalFields = (data: UserPersonalData) => ({
  countryCode: data.countryCode,
  city: data.city,
  age: data.age,
  gender: data.gender,
  languages: data.languages,
});

export const googleSignIn = (
  error: (num?: number) => void): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    try {
      dispatch(setLoading(true));
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      if (user) {
        const response = await dispatch(fetchUserDetails(user.uid));
        if (response.payload === 404) {
          dispatch(setLoading(false));
          error(404);
          return false;
        }
        dispatch(setLoading(false));
        return true;
      }
    } catch (err) {
      error();
    }
    dispatch(setLoading(false));
    return false;
  };

export const signIn = (
  email: string,
  password: string,
  success: () => void,
  error: (num?: number) => void,
  passEmailErrorMessage: (errorMessage: string) => void,
): AppThunk =>
  async (dispatch) => {
    const auth = getAuth();
    try {
      return await signInWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          const user = userCredential.user;
          if (user) {
            const response = await dispatch(fetchUserDetails(user.uid));
            if (response.payload === 404) {
              error(404);
            } else {
              success();
            }
          }
        })
        .catch((err) => {
          const errorMessage: string = err.message;
          error();
          passEmailErrorMessage(errorMessage);
        });
    } catch (err) {
      error();
    }
  };

export const googleSignUp = (
  data: UserRegistrationByGoogle<UserPersonalData | CreateUserRecruiterPartData>,
  error: () => void,
): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    try {
      dispatch(setLoading(true));
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      if (user) {
        const response = await getUserAPI(user.uid);

        if (typeof response === 'number') {
          const userRecruiter = data as UserRegistrationByGoogle<CreateUserRecruiterPartData>;
          const userHelperOrRefugee = data as UserRegistrationByGoogle<UserPersonalData>;

          const commonFields = {
            userId: user.uid,
            email: user.email || '',
            role: data.role,
            firstName: data.firstName,
            lastName: data.lastName,
          };

          switch (data.role) {
          case USER_ROLE.RECRUITER:
            await dispatch(createNewUser({
              ...commonFields,
              position: userRecruiter.position,
              companyInfo: userRecruiter.companyInfo,
            }));
            break;
          case USER_ROLE.HELPER:
            await dispatch(createNewUser({
              ...commonFields,
              ...personalFields(userHelperOrRefugee),
            }));
            break;
          case USER_ROLE.REFUGEE:
            await dispatch(createNewUser({
              ...commonFields,
              ...personalFields(userHelperOrRefugee),
            }));
            break;
          default:
            break;
          }
          dispatch(setLoading(false));
          return true;
        } else {
          dispatch(setLoading(false));
          error();
          dispatch(logout());
          return false;
        }
      } else {
        dispatch(setLoading(false));
        error();
        return false;
      }
    } catch (err) {
      dispatch(setLoading(false));
      dispatch(logout());
      error();
      return false;
    }
  };

export const signUp = (
  data: UserRegistrationByEmail<UserPersonalData | CreateUserRecruiterPartData>,
  success: () => void,
  error: () => void,
): AppThunk => async (dispatch) => {
  const auth = getAuth();
  try {
    dispatch(setLoading(true));
    const user = await createUserWithEmailAndPassword(auth, data.email, data.password);
    if (user) {
      const response = await getUserAPI(user.user.uid);
      if (typeof response === 'number') {
        const userRecruiter = data as UserRegistrationByEmail<CreateUserRecruiterPartData>;
        const userHelperOrRefugee = data as UserRegistrationByEmail<UserPersonalData>;

        const commonFields = {
          userId: user.user.uid,
          email: user.user.email || '',
          role: data.role,
          firstName: data.firstName,
          lastName: data.lastName,
        };

        switch (data.role) {
        case USER_ROLE.RECRUITER:
          await dispatch(createNewUser({
            ...commonFields,
            position: userRecruiter.position,
            companyInfo: userRecruiter.companyInfo,
          }));
          break;
        case USER_ROLE.HELPER:
          await dispatch(createNewUser({
            ...commonFields,
            ...personalFields(userHelperOrRefugee),
          }));
          break;
        case USER_ROLE.REFUGEE:
          await dispatch(createNewUser({
            ...commonFields,
            ...personalFields(userHelperOrRefugee),
          }));
          break;
        default:
          break;
        }
        dispatch(setLoading(false));
        success();
        return false;
      } else {
        dispatch(setLoading(false));
        dispatch(logout());
        error();
        return false;
      }
    }
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(logout());
    error();
    return false;
  }
};

export const logout = (): AppThunk => async (dispatch) => {
  const auth = getAuth();
  try {
    await signOut(auth);
    dispatch(resetUserState());

  } catch (error) {
    // eslint-disable-next-line 
    console.error(error);
  }
};

export const verifyEmail = (): AppThunk => async () => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (user) {
    try {
      await sendEmailVerification(user);
    } catch (err) {
      // TODO - Handle error if user is not in auth
    }
  }
};

// Send a password reset email (forgot)
export const resetPassword = (email: string): AppThunk => async () => {
  const auth = getAuth();
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (err) {
    // TODO - Handle error if user is not in auth
  }
};

// Change user password (settings)
export const changePassword = (
  password: string,
  newPassword: string,
  errors: { wrongPassword: string, loginBeforeChangePassword: string },
  succesfull: { passwordChanged: string }
):
  AppThunk => async (dispatch, getState) => {
  const authState = getState().auth.user;
  const auth = getAuth();
  const user = auth.currentUser;

  const error = () => {
    dispatch(setAuthError(errors.wrongPassword));
  };

  if (user) {
    try {
      await dispatch(reauthenticateUser(authState, error, password)); // Await reauthentication
      await updatePassword(user, newPassword);
      return dispatch(setAuthSuccess(succesfull.passwordChanged));
    } catch (err) {
      return dispatch(setAuthError(errors.loginBeforeChangePassword));
    }
  }
};

export const reauthenticateUser = (
  authState: SerializableUser | null,
  error: () => void,
  password?: string,
): AppThunk<Promise<void>> => () => {
  return new Promise<void>((resolve, reject) => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      const providerId = authState?.providerId;
      let credential;
      if (providerId === 'password') {
        if (password) {
          credential = EmailAuthProvider.credential(authState?.email as string, password);
        } else {
          error();
          reject(new Error('No password provided'));
        }
      } else if (providerId === 'google.com') {
        resolve();
      } else {
        error();
        reject(new Error('Invalid providerId'));
      }
      if (credential) {
        reauthenticateWithCredential(user, credential)
          .then(() => resolve())
          .catch(reject);
      }
    } else {
      error();
      reject(new Error('No user'));
    }
  });
};



export const removeUser = (
  error: () => void,
  password?: string,
): AppThunk => async (dispatch, getState) => {
  const authState = getState().auth.user;
  const auth = getAuth();
  const user = auth.currentUser;

  if (user) {
    try {
      await dispatch(reauthenticateUser(authState, error, password));
      const response = await deleteUserAPI();

      if (response.status === 204) {
        dispatch(resetUserState());
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (err) {
      error();
    }
  } else {
    error();
  }
};

export const authStateListener = () => {
  return (dispatch: AppDispatch) => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      dispatch(setLoading(true));
      if (user) {
        const serializableUser = extractSerializableUserData(user);
        dispatch(setUser(serializableUser));
      } else {
        dispatch(setUser(null));
      }
      dispatch(setLoading(false));
    });

    // Return the unsubscribe function to be able to clean up the listener
    return unsubscribe;
  };
};


export default authSlice.reducer;
