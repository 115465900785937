import { BiLoaderCircle } from 'react-icons/bi';
import { MdGTranslate } from 'react-icons/md';
import { useButtonsTranslations } from './translations';
interface TranslateButtonProps {
  translation: string;
  loader: boolean;
  onBackToDesc: () => void;
  getTranslation: () => void;
  customPosition?: string;
}

function TranslateButton({
  translation,
  loader,
  onBackToDesc,
  getTranslation,
  customPosition,
}: TranslateButtonProps) {
  const translate = useButtonsTranslations();
  const position = ` ${customPosition}` || ' absolute top-1 right-1';

  return (
    <button
      type="button"
      className={'flex items-center justify-center gap-1' +
        ' text-blue-700 hover:text-blue-800 text-sm font-medium' +
        ' transition-colors duration-300 easy-in-out' + position}
      onClick={(e) => {
        translation?.length ?
          onBackToDesc() : getTranslation();
        e.stopPropagation();
      }}
      disabled={loader}
    >
      {loader ? <BiLoaderCircle className="h-6 w-6" /> :
        <MdGTranslate className="h-6 w-6" />}
      {translation?.length ? `${translate.backToOriginal}` : `${translate.translate}`}
    </button>
  );
}

export default TranslateButton;
