import * as z from 'zod';
import { contactUsSchemaForm } from 'data/schemaForm';
import { getAuth } from 'firebase/auth';

type ContactUsForm = z.infer<typeof contactUsSchemaForm>;

const API_BASE = process.env.REACT_APP_API_BASE_URL;

export async function sendRequestToHostAPI({ id, message }: MessageData): Promise<Response> {
  const url = `${API_BASE}/api/v1/accommodations/${id}/send-message`;
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User not logged in');
  }
  const token = await user.getIdToken();

  const response = await fetch(`${url}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: message,
  });

  return response;
}

export async function sendRequestToRoommateAPI({ id, message }: MessageData): Promise<Response> {
  const url = `${API_BASE}/api/v1/users/${id}/roommate-profile/send-message`;
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User not logged in');
  }
  const token = await user.getIdToken();

  const response = await fetch(`${url}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: message,
  });

  return response;
}

export async function sendContactUsMessageAPI({
  email, subject, message }: ContactUsForm): Promise<Response> {
  const url = `${API_BASE}/api/v1/users/contact-us`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      subject,
      message,
    }),
  });

  return response;
}
