import { getAuth } from 'firebase/auth';
import { parseResponseJSON } from 'utils/apiHelper';

const API_BASE = process.env.REACT_APP_API_BASE_URL;

export async function getRemoteJobsAPI(): Promise<JobPreview[]> {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken();

  const response = await fetch(`${API_BASE}/api/v1/remoteJobs`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });

  if (!response.ok) {
    return Promise.reject(response.status);
  } else {
    return parseResponseJSON(response, []);
  }
}


export async function getJobsAPI(data: JobsRequest): Promise<JobsResponse> {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken();
  const raw = JSON.stringify({ ...data });
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  const response = await fetch(
    `${API_BASE}/api/v1/remoteJobs/search`,
    requestOptions,
  );

  if (!response.ok) {
    return Promise.reject(response.status);
  } else {
    return parseResponseJSON(response);
  }
}


export async function getJobByIdAPI(id: string): Promise<JobFull> {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken();
  const response = await fetch(
    `${API_BASE}/api/v1/job/${id}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });

  if (!response.ok) {
    return Promise.reject(response.status);
  } else {
    return parseResponseJSON(response);
  }
}
