export const USER_ROLE = {
  RECRUITER: 'RECRUITER',
  HELPER: 'HELPER',
  REFUGEE: 'REFUGEE',
} as const;

export const ACCOMMODATION_TYPE = {
  WHOLE_PLACE: 'WHOLE_PLACE',
  ROOM: 'ROOM',
} as const;

export const ACCEPTED_GENDER = {
  ANY: 'ANY',
  MALE: 'MALE',
  FEMALE: 'FEMALE',
} as const;

export const PET_TYPE = {
  CAT: 'CAT',
  DOG: 'DOG',
  BIRD: 'BIRD',
  REPTILE: 'REPTILE',
  OTHER: 'OTHER',
} as const;

export const PET_SIZE = {
  S: 'S',
  M: 'M',
  L: 'L',
} as const;

export const DURATION = {
  UNDER_1_MONTH: 'UNDER_1_MONTH',
  MONTHS_1_3: 'MONTHS_1_3',
  MONTHS_4_6: 'MONTHS_4_6',
  MORE_THAN_6_MONTHS: 'MORE_THAN_6_MONTHS',
} as const;

export const JOB_INDUSTRY = {
  // new industries
  AGRICULTURE: 'AGRICULTURE',
  BEAUTY_FITNESS: 'BEAUTY_FITNESS',
  CONSTRUCTION_ENGINEERING: 'CONSTRUCTION_ENGINEERING',
  DESIGN_ART_CULTURE: 'DESIGN_ART_CULTURE',
  EDUCATION_SCIENCE: 'EDUCATION_SCIENCE',
  FINANCE_BANKING: 'FINANCE_BANKING',
  HORECA_TOURISM: 'HORECA_TOURISM',
  IT: 'IT',
  LEGAL: 'LEGAL',
  LOGISTICS_WAREHOUSING_TRANSPORT: 'LOGISTICS_WAREHOUSING_TRANSPORT',
  MANAGEMENT_ADMINISTRATION: 'MANAGEMENT_ADMINISTRATION',
  MARKETING_PR: 'MARKETING_PR',
  MEDICAL: 'MEDICAL',
  OTHER: 'OTHER',
  PRODUCTION_FACTORIES: 'PRODUCTION_FACTORIES',
  SALES_RETAIL: 'SALES_RETAIL',
  SECRETARY_OFFICE_MANAGEMENT_HR: 'SECRETARY_OFFICE_MANAGEMENT_HR',
} as const;

export const CONTRACT_TYPE = {
  FULL_TIME: 'FULL_TIME',
  PART_TIME: 'PART_TIME',
  SEASONAL: 'SEASONAL',
} as const;

export const EXPERIENCE_LEVEL = {
  NO_PRIOR_EXPERIENCE_REQUIRED: 'NO_PRIOR_EXPERIENCE_REQUIRED',
  UP_TO_1_YEAR: 'UP_TO_1_YEAR',
  YEARS_1_3: 'YEARS_1_3',
  YEARS_4_7: 'YEARS_4_7',
  YEARS_8_OR_MORE: 'YEARS_8_OR_MORE',
} as const;
