
import { Trans } from 'react-i18next';

function ErrorBoundaryDescription() {

  return (
    <p className="text-center text-lg font-normal text-grey-700">
      <Trans
        i18nKey="errorBoundary.description"
        components={{ br: <br /> }}
      />
    </p>
  );
}

export default ErrorBoundaryDescription;
