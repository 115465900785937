import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getJobByIdAPI,
  getJobsAPI,
  getRemoteJobsAPI,
} from 'api/remoteJob';

interface RemoteJobsState {
  jobsCarousel: JobPreview[];
  jobs: JobCard[];

  loading: boolean;
  error: boolean;
  success: boolean;

  filters: Partial<JobFilters>;
  filtersIsApplied: boolean;

  totalCount: number;
  currentPage: number;
  totalPages: number;

  selectedJob: JobFull | null;
}

const initialState: RemoteJobsState = {
  jobsCarousel: [],
  jobs: [],

  loading: false,
  error: false,
  success: false,

  filters: {},
  filtersIsApplied: false,

  totalCount: 0,
  currentPage: 1,
  totalPages: 0,

  selectedJob: null,
};

export const getJobsForCarousel = createAsyncThunk(
  'remoteJobs/getJobsForCarousel',
  async (_: void, { rejectWithValue }) => {
    try {
      return await getRemoteJobsAPI();
    } catch (error) {
      return rejectWithValue('');
    }
  }
);

export const getJobs = createAsyncThunk(
  'remoteJobs/getJobs',
  async (data: JobsRequest, { rejectWithValue }) => {
    try {
      return await getJobsAPI(data);
    } catch (error) {
      return rejectWithValue('');
    }
  }
);

export const getJobById = createAsyncThunk(
  'user/getJobById',
  async function (id: string, { rejectWithValue }) {
    try {
      return await getJobByIdAPI(id);
    } catch (error) {
      return rejectWithValue('');
    }
  }
);

const remoteJobsSlice = createSlice({
  name: 'remoteJobs',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.loading = false;
      state.error = false;
      state.success = false;
    },
    setJobLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFilters: (state, action: PayloadAction<{}>) => {
      state.filters = action.payload;
    },
    setSelectedJob: (state, action: PayloadAction<JobFull | null>) => {
      state.selectedJob = action.payload;
    },
    resetSelectedJob: (state) => {
      state.selectedJob = null;
    },
    setFiltersIsApplied: (state, action: PayloadAction<boolean>) => {
      state.filtersIsApplied = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getJobsForCarousel.pending, (state) => {
        state.jobsCarousel = [];
        state.loading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(getJobsForCarousel.fulfilled,
        (state, action) => {
          state.jobsCarousel = action.payload;
          state.loading = false;
          state.error = false;
          state.success = true;
        })
      .addCase(getJobsForCarousel.rejected, (state) => {
        state.jobsCarousel = [];
        state.loading = false;
        state.error = true;
        state.success = false;
      })
      .addCase(getJobs.pending, (state) => {
        state.jobs = [];
        state.loading = true;
        state.totalCount = 0;
        state.currentPage = 1;
        state.totalPages = 0;
      })
      .addCase(getJobs.fulfilled,
        (state, action: PayloadAction<JobsResponse>) => {
          state.loading = false;
          state.jobs = action.payload?.allJobs ?? [];
          state.totalCount = action.payload?.totalCount ?? 0;
          state.currentPage = action.payload?.currentPage ?? 1;
          state.totalPages = action.payload?.totalPages ?? 0;
        })
      .addCase(getJobs.rejected, (state) => {
        state.loading = false;
        state.jobs = [];
        state.totalCount = 0;
        state.currentPage = 1;
        state.totalPages = 0;
      })
      .addCase(getJobById.pending, (state) => {
        state.loading = true;
        state.selectedJob = null;
      })
      .addCase(getJobById.fulfilled,
        (state, action: PayloadAction<JobFull | null>) => {
          state.loading = false;
          state.selectedJob = action.payload ?? null;
        })
      .addCase(getJobById.rejected, (state) => {
        state.loading = false;
        state.selectedJob = null;
      });
  }
});

export const {
  resetStatus,
  setJobLoading,
  setFilters,
  setSelectedJob,
  resetSelectedJob,
  setFiltersIsApplied,
} = remoteJobsSlice.actions;

export default remoteJobsSlice.reducer;
