import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { useTranslation } from 'react-i18next';

type GoogleButtonProps = {
  typeText: 'signin' | 'signup';
  loader?: boolean;
} & DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

function GoogleButton({ typeText, loader, ...props }: GoogleButtonProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'other' });

  return (
    <button
      {...props}
      className={'flex w-full items-center justify-center gap-4' +
        ' rounded-[50px] border border-grey-600 bg-white px-2 py-[11px]' +
        `${props.disabled ? ' opacity-50 cursor-not-allowed' : ''}`}
    >
      {loader ?
        <FaSpinner className="animate-spin text-xl text-grey-800" /> :
        <FcGoogle className="text-red-600 text-xl" />}
      <span className="text-sm font-medium text-grey-800">
        {typeText === 'signin' ? t('signInGoogle') : t('signUpGoogle')}
      </span>
    </button>
  );
}

export default GoogleButton;
