import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createHelperAccomodationAPI,
  deleteHelperAccommodationByIdAPI,
  getHelperAccomodationsAPI,
  onChangeAvailableAccomodationAPI,
  updateHelperAccomodationByIdAPI,
} from 'api/accommodationsHelper';

export const createHelperAccomodation = createAsyncThunk(
  'helper/createHelperAccomodation',
  async function (data: HelperAccommodationRequest, { rejectWithValue }) {
    try {
      await createHelperAccomodationAPI(data);
      // TODO refactor!!!
      return {success : true};
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getHelperAccommodations = createAsyncThunk(
  'helper/getHelperAccommodations',
  async function (_: void, { rejectWithValue }) {
    try {
      const response = await getHelperAccomodationsAPI();

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateHelperAccomodation = createAsyncThunk(
  'helper/updateHelperAccomodation',
  async function (data: HelperAccommodationRequest, { rejectWithValue }) {
    try {
      const response = await updateHelperAccomodationByIdAPI(data);
      if (response.status === 204) {
        return;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteHelperAccommodation = createAsyncThunk(
  'helper/deleteHelperAccommodation',
  async function (accommodationId: string, { rejectWithValue }) {
    try {
      const response = await deleteHelperAccommodationByIdAPI(accommodationId);
      if (response.status === 204) {
        return;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const onChangeAvailableAccomodation = createAsyncThunk(
  'helper/onChangeAvailableAccomodation',
  async function (
    data: { accommodationId: string; enabled: boolean },
    { rejectWithValue }) {
    try {
      const response = await onChangeAvailableAccomodationAPI(data);
      if (response.status === 204) {
        return;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

interface HelperState {
  myAccommodations: HelperAccommodation[] | null;
  defaultAccData: HelperAccommodation | null;
  created: boolean;
  loading: boolean;
  error: string | null;
  loadingUpdate: boolean;
  errorUpdate: string | null;
  successUpdate: boolean;
  loadingDelete: boolean;
  errorDelete: string | null;
  successDelete: boolean;
  successAvailable: boolean;
  errorAvailable: string | null;
}

const initialState: HelperState = {
  myAccommodations: null,
  defaultAccData: null,
  created: false,
  loading: false,
  error: null,
  loadingUpdate: false,
  errorUpdate: null,
  successUpdate: false,
  loadingDelete: false,
  errorDelete: null,
  successDelete: false,
  successAvailable: false,
  errorAvailable: null,
};

const accommodationsHelperSlice = createSlice({
  name: 'helper',
  initialState,
  reducers: {
    resetCreatedAccomodation: (state) => {
      state.created = false;
    },
    resetErrorAccommodation: (state) => {
      state.error = null;
    },
    resetErrorUpdateAccommodation: (state) => {
      state.errorUpdate = null;
    },
    resetSuccessUpdateAccommodation: (state) => {
      state.successUpdate = false;
    },
    resetErrorAvailableAccommodation: (state) => {
      state.errorAvailable = null;
    },
    resetSuccessAvailableAccommodation: (state) => {
      state.successAvailable = false;
    },
    resetErrorDeleteAccommodation: (state) => {
      state.errorDelete = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createHelperAccomodation.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.created = false;
      })
      .addCase(createHelperAccomodation.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
        state.created = true;
      })
      .addCase(createHelperAccomodation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? null;
        state.created = false;
      })
      .addCase(getHelperAccommodations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getHelperAccommodations.fulfilled,
        (state, action: PayloadAction<HelperAccommodation[] | null>,
        ) => {
          state.loading = false;
          state.error = null;
          if (action.payload && action.payload?.length &&
            action.payload[0].accommodationId === null) {
            state.myAccommodations = [];
            state.defaultAccData = action.payload[0];
          } else {
            state.myAccommodations = action.payload;
            state.defaultAccData = null;
          }
        })
      .addCase(getHelperAccommodations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? null;
        state.myAccommodations = [];
      })
      .addCase(updateHelperAccomodation.pending, (state) => {
        state.loadingUpdate = true;
        state.errorUpdate = null;
        state.successUpdate = false;
      })
      .addCase(updateHelperAccomodation.fulfilled, (state) => {
        state.loadingUpdate = false;
        state.errorUpdate = null;
        state.successUpdate = true;
      })
      .addCase(updateHelperAccomodation.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.errorUpdate = action.error.message ?? null;
        state.successUpdate = false;
      })
      .addCase(onChangeAvailableAccomodation.pending, (state) => {
        state.loadingUpdate = true;
        state.errorAvailable = null;
        state.successAvailable = false;
      })
      .addCase(onChangeAvailableAccomodation.fulfilled, (state) => {
        state.successAvailable = true;
        state.errorAvailable = null;
        state.loadingUpdate = false;
      })
      .addCase(onChangeAvailableAccomodation.rejected, (state, action) => {
        state.successAvailable = false;
        state.errorAvailable = action.error.message ?? null;
        state.loadingUpdate = false;
      })
      .addCase(deleteHelperAccommodation.pending, (state) => {
        state.loadingDelete = true;
        state.errorDelete = null;
        state.successDelete = false;
      })
      .addCase(deleteHelperAccommodation.fulfilled, (state) => {
        state.loadingDelete = false;
        state.errorDelete = null;
        state.successDelete = true;
      })
      .addCase(deleteHelperAccommodation.rejected, (state, action) => {
        state.loadingDelete = false;
        state.errorDelete = action.error.message ?? null;
        state.successDelete = false;
      });
  },
});

export const {
  resetCreatedAccomodation,
  resetErrorAccommodation,
  resetErrorUpdateAccommodation,
  resetSuccessUpdateAccommodation,
  resetErrorDeleteAccommodation,
  resetErrorAvailableAccommodation,
  resetSuccessAvailableAccommodation,
} = accommodationsHelperSlice.actions;

export default accommodationsHelperSlice.reducer;
