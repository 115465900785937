import { getAuth } from 'firebase/auth';

const API_BASE = process.env.REACT_APP_API_BASE_URL;

// for browsing accommodations as a refugee
export async function getAccommodationsAPI(data: AccommodationRefugeeRequest)
  : Promise<AccommodationsResponse | Partial<AccommodationsResponse>> {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken();
  const raw = JSON.stringify(data);
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  const response = await fetch(
    `${API_BASE}/api/v1/accommodations/search`,
    requestOptions,
  )
    .then((response: Response) => {
      if (response.status !== 200) {
        throw new Error('Error fetching accommodations');
      }
      return response?.json() || {};
    })
    .then((result: AccommodationsResponse | Partial<AccommodationsResponse>) => result);

  return response;
}

// for browsing accommodations on the map as a refugee
export async function getAccommodationsForMapAPI(
  filters: Partial<HelperAccommodationRequest>): Promise<AccommodationsMapResponse> {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken();
  const raw = JSON.stringify({ filters });
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  const response = await fetch(
    `${API_BASE}/api/v1/accommodations/search/map`,
    requestOptions,
  ).then((response: Response) => response?.json() || {
    allAccommodations: [],
    totalCount: 0,
  })
    .then((result: AccommodationsMapResponse) => result);

  return response;
}

// for browsing accommodations as a refugee
export async function getAccommodationByIdAPI(id: string): Promise<RefugeeAccommodation | null> {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken();

  const response = await fetch(`${API_BASE}/api/v1/accommodations/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  })
    .then((response: Response) => response?.json() || [])
    .then((result: RefugeeAccommodation) => result)
    .catch(() => null);

  return response || null;
}

// for creating a new accommodation profile as a refugee
export async function createRefugeeAccomodationProfileAPI(
  data: Partial<FamilyInfo>): Promise<Response> {
  const url = `${API_BASE}/api/v1/users/accommodation-profile`;
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User not logged in');
  }
  const token = await user.getIdToken();

  const response = await fetch(`${url}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data),
  });

  return response;
}

// for updating an existing accommodation profile as a refugee
export async function updateRefugeeAccomodationProfileAPI(
  data: Partial<FamilyInfo>): Promise<Response> {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken();

  const requestOptions: RequestInit = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data),
  };

  return await fetch(
    `${API_BASE}/api/v1/users/accommodation-profile/update`,
    requestOptions,
  );
}
