import { ChatboxColors, Crisp } from 'crisp-sdk-web';
import { useEffect, useState, useTransition } from 'react';
import CookiePolicy from 'components/Modals/CookiePolicy';
import ErrorBoundary from 'components/ErrorBoundary';
// import Hotjar from '@hotjar/browser';
import { Outlet } from 'react-router-dom';
import { authStateListener } from './store/slices/authSlice';
import { useAppDispatch } from './store';
import './index.css';

interface CustomWindow extends Window {
  dataLayer: Record<string, unknown>[];  
}

interface DataLayerObject {
  event: string;
  [key: string]: unknown; // Use specific types if possible
}



declare let window: CustomWindow;


function App() {
  const dispatch = useAppDispatch();
  const [, startTransition] = useTransition();
  const [showCookiePolicy, setShowCookiePolicy] = useState(false);
  const GA_CONSENT_KEY = 'gaConsent';


  function disableGoogleAnalytics(): void {
    // Check if Google Analytics objects exist
    if (window.dataLayer) {
      // Push a command to the data layer to disable tracking
      const disableGA: DataLayerObject = {
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
        'gtm.blacklist': ['google-analytics']
      };
      window.dataLayer.push(disableGA);
    }
    const gtmScript = document.querySelector('script[src*="googletagmanager.com/gtm.js"]');
    if (gtmScript && gtmScript.parentNode) {
      gtmScript.parentNode.removeChild(gtmScript);
    }
  
    // Remove GTM noscript iframe
    const gtmNoScript = 
      document.querySelector('noscript > iframe[src*="googletagmanager.com/ns.html"]');
    if (gtmNoScript && gtmNoScript.parentNode) {
      gtmNoScript.parentNode.removeChild(gtmNoScript);
    }
    
    // Remove GTM noscript iframe along with the noscript tag
    const gtmNoScriptIframe = document.querySelector('noscript');
    if (gtmNoScriptIframe) {
      gtmNoScriptIframe.remove();
    }

  
    // Remove the Google Analytics cookies
    document.cookie.split(';').forEach(cookie => {
      if (cookie.trim().startsWith('_ga')) {
        document.cookie = `${cookie};expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
      }
      if (cookie.trim().startsWith('ar')) {
        document.cookie = `${cookie};expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
      }
    });
  }


  
  
  useEffect(() => {
    const gaConsent = localStorage.getItem(GA_CONSENT_KEY);
    if (gaConsent === null) {
      // User hasn't made a choice yet, show the CookiePolicy modal
      setShowCookiePolicy(true);
    } else if (gaConsent === 'declined') {
      disableGoogleAnalytics();
    }


    dispatch(authStateListener());
    startTransition(() => {
      if (process.env.REACT_APP_FIREBASE_PROJECT_ID === 'eu4ua-83899') {
        // const siteId = 3705972;
        // const hotjarVersion = 6;
        // Hotjar.init(siteId, hotjarVersion);
      }
      Crisp.configure(process.env.REACT_APP_CRISP_WEBSITE_ID as string);
      Crisp.setColorTheme(ChatboxColors.LightBlue);
    });
  }, []);

  return (
    <ErrorBoundary childComponentName="inApp">
      {showCookiePolicy && (
        <CookiePolicy open="on" 
          onClose={() => {
            localStorage.setItem(GA_CONSENT_KEY, 'declined');
            disableGoogleAnalytics();
            setShowCookiePolicy(false);
          }} 
          onAccept={() => {
            localStorage.setItem(GA_CONSENT_KEY, 'accepted');
            setShowCookiePolicy(false);
          }}
        />
      )}
      <Outlet />
    </ErrorBoundary>
  );
}

export default App;
