import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

type Props = {
  classNames?: string;
  text: string;
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

function Button({ classNames, text, ...props }: Props) {

  return (
    <button
      {...props}
      className={`${classNames ? classNames : ''}`}
      style={{ borderRadius: '50px', ...props.style }}
    >
      {text}
    </button>
  );
}

export default Button;
