import { getAuth } from 'firebase/auth';

const API_BASE = process.env.REACT_APP_API_BASE_URL;

// for creating a new accommodation as a helper
export async function createHelperAccomodationAPI(
  data: HelperAccommodationRequest): Promise<Response> {
  const url = `${API_BASE}/api/v1/accommodations`;
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User not logged in');
  }
  const token = await user.getIdToken();

  const response = await fetch(`${url}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data),
  });

  return response;
}

// for getting all accommodations of a helper
export async function getHelperAccomodationsAPI(): Promise<HelperAccommodation[] | null> {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken();

  const response = await fetch(`${API_BASE}/api/v1/accommodations`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  })
    .then((response: Response) => response?.json() || [])
    .then((result: HelperAccommodation[]) => result)
    .catch(() => []);

  return response || null;
}

/// for updating accommodation as a helper
export async function updateHelperAccomodationByIdAPI(
  data: HelperAccommodationRequest): Promise<Response> {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken();

  const response = await fetch(
    `${API_BASE}/api/v1/accommodations/${data.accommodationId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ ...data, helperId: user.uid }),
    });

  return response;
}

// for updating accommodation as a helper
export async function onChangeAvailableAccomodationAPI({
  accommodationId, enabled,
}: { accommodationId: string; enabled: boolean }): Promise<Response> {
  const api = `${API_BASE}/api/v1/accommodations/activate` +
    `?accommodationId=${accommodationId}&isActive=${enabled}`;
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken();

  const response = await fetch(api, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });

  return response;
}

// for deleting accommodation as a helper
export async function deleteHelperAccommodationByIdAPI(
  accommodationId: string,
): Promise<Response> {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken();

  const response = await fetch(
    `${API_BASE}/api/v1/accommodations/${accommodationId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });

  return response;
}
