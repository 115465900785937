import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createRoommateAPI,
  deleteRoommateProfileAPI,
  getRoommatesAPI,
  getRoommatesByIdAPI,
  getRoommatesForMapAPI,
  getRoommatesPreviewAllAPI,
  onChangeAvailableRoommateProfileAPI,
  updateRoommateProfileAPI,
} from 'api/roommatesRefugee';

interface RoommatesState {
  roommates: BaseRoommateProfile[];
  filters: Partial<RoommateFilters>;
  selectedRoommate: FullRoommateProfile | null;
  totalCount: number;
  currentPage: number;
  totalPages: number;
  filtersIsApplied: boolean;

  roommatesLoading: boolean;
  createRoommateLoading: boolean;
  createRoommateError: string | null;
  createRoommateSuccess: boolean;
  updateRoommateProfileError: string | null;
  updateRoommateProfileLoading: boolean;
  updateRoommateProfileSuccess: boolean;
  errorDelete: string | null;
  successDelete: boolean;
  errorAvailable: string | null;
  successAvailable: boolean;

  roommatesPreviewAll: RoommateProfilePreview[];
  hasErrorPreviewAll: boolean;
  isLoadingPreviewAll: boolean;
}

export const initialState: RoommatesState = {
  roommates: [],
  filters: {},
  selectedRoommate: null,
  totalCount: 0,
  currentPage: 1,
  totalPages: 0,
  filtersIsApplied: false,

  roommatesLoading: false,
  createRoommateLoading: false,
  createRoommateError: null,
  createRoommateSuccess: false,
  updateRoommateProfileError: null,
  updateRoommateProfileLoading: false,
  updateRoommateProfileSuccess: false,
  errorDelete: null,
  successDelete: false,
  errorAvailable: null,
  successAvailable: false,

  roommatesPreviewAll: [],
  hasErrorPreviewAll: false,
  isLoadingPreviewAll: false,
};

export const getRoommates = createAsyncThunk(
  'user/getRoommates',
  async (data: RoommateProfileRequest, { rejectWithValue }) => {
    try {
      return await getRoommatesAPI(data) as RoommateProfileResponse | void;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getRoommatesPreviewAll = createAsyncThunk(
  'user/getRoommatesPreviewAll',
  async (_: void, { rejectWithValue }) => {
    try {
      return await getRoommatesPreviewAllAPI();
    } catch (error) {
      return rejectWithValue('');
    }
  }
);

export const getRoommatesOnMap = createAsyncThunk(
  'user/getRoommatesOnMap',
  async (filters: Partial<RoommateFilters>, { rejectWithValue }) => {
    try {
      const response = await getRoommatesForMapAPI(filters);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getRoommateById = createAsyncThunk(
  'user/getRoommateById',
  async function (id: string, { rejectWithValue }) {
    try {
      const response = await getRoommatesByIdAPI(id);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createRoommateProfile = createAsyncThunk(
  'user/createRoommateProfile',
  async (data: UserRoommateProfile, { rejectWithValue }) => {
    try {
      const responseOk = await createRoommateAPI(data);
      if (responseOk) {
        return;
      } else {
        return rejectWithValue('Error');
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateRoommateProfile = createAsyncThunk(
  'refugee/updateRoommateProfile',
  async function (data: UserRoommateProfile, { rejectWithValue }) {
    try {
      return await updateRoommateProfileAPI(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const onChangeAvailableRoommateProfile = createAsyncThunk(
  'refugee/onChangeAvailableRoommateProfile',
  async function (available: boolean, { rejectWithValue }) {
    try {
      const response = await onChangeAvailableRoommateProfileAPI(available);
      if (response.status === 204) {
        return;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteRoommateProfile = createAsyncThunk(
  'refugee/deleteRoommateProfile',
  async function (_: void, { rejectWithValue }) {
    try {
      const response = await deleteRoommateProfileAPI();
      if (response.status === 204) {
        return;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const roommatesSlice = createSlice({
  name: 'roommates',
  initialState,
  reducers: {
    setRoommates: (state, action: PayloadAction<RoommatesState['roommates']>) => {
      state.roommates = action.payload;
    },
    resetCreateRoommate: (state) => {
      state.createRoommateError = null;
      state.createRoommateSuccess = false;
      state.createRoommateLoading = false;
    },
    setRoommatesLoading: (state, action: PayloadAction<boolean>) => {
      state.roommatesLoading = action.payload;
    },
    setFilters: (state, action: PayloadAction<{}>) => {
      state.filters = action.payload;
    },
    resetSelectedRoommate: (state) => {
      state.selectedRoommate = {} as FullRoommateProfile;
    },
    setFiltersIsApplied: (state, action: PayloadAction<boolean>) => {
      state.filtersIsApplied = action.payload;
    },
    resetRoommateProfileError(state) {
      state.updateRoommateProfileError = null;
    },
    resetRoommateProfileSuccess(state) {
      state.updateRoommateProfileSuccess = false;
    },
    resetErrorAvailableRoommateProfile: (state) => {
      state.errorAvailable = null;
    },
    resetSuccessAvailableRoommateProfile: (state) => {
      state.successAvailable = false;
    },
    resetErrorDeleteRoommateProfile: (state) => {
      state.errorDelete = null;
    },
    resetErrorRoommatesPreviewAll: (state) => {
      state.hasErrorPreviewAll = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRoommates.pending, (state) => {
        state.roommates = [];
        state.roommatesLoading = true;
        state.totalCount = 0;
        state.currentPage = 1;
        state.totalPages = 0;
      })
      .addCase(getRoommates.fulfilled,
        (state, action: PayloadAction<RoommateProfileResponse | void>) => {
          state.roommatesLoading = false;
          state.roommates = action.payload?.allRoommateProfiles ?? [];
          state.totalCount = action.payload?.totalCount ?? 0;
          state.currentPage = action.payload?.currentPage ?? 1;
          state.totalPages = action.payload?.totalPages ?? 0;
        })
      .addCase(getRoommates.rejected, (state) => {
        state.roommatesLoading = false;
        state.roommates = [];
        state.totalCount = 0;
        state.currentPage = 1;
        state.totalPages = 0;
      })
      .addCase(getRoommatesOnMap.pending, (state) => {
        state.roommates = [];
        state.roommatesLoading = true;
        state.totalCount = 0;
      })
      .addCase(getRoommatesOnMap.fulfilled,
        (state, action: PayloadAction<RoommateProfileMapResponse>) => {
          state.roommatesLoading = false;
          state.roommates = action.payload.allRoommateProfiles;
          state.totalCount = action.payload.totalCount;
        })
      .addCase(getRoommatesOnMap.rejected, (state) => {
        state.roommatesLoading = false;
        state.roommates = [];
        state.totalCount = 0;
      })
      .addCase(getRoommateById.pending, (state) => {
        state.roommatesLoading = true;
        state.selectedRoommate = null;
      })
      .addCase(getRoommateById.fulfilled,
        (state, action: PayloadAction<FullRoommateProfile | null>) => {
          state.roommatesLoading = false;
          state.selectedRoommate = action.payload ?? null;
        })
      .addCase(getRoommateById.rejected, (state) => {
        state.roommatesLoading = false;
        state.selectedRoommate = null;
      })
      .addCase(createRoommateProfile.pending, (state) => {
        state.createRoommateLoading = true;
        state.createRoommateSuccess = false;
        state.createRoommateError = null;
      })
      .addCase(createRoommateProfile.fulfilled, (state) => {
        state.createRoommateLoading = false;
        state.createRoommateSuccess = true;
        state.createRoommateError = null;
      })
      .addCase(createRoommateProfile.rejected, (state) => {
        state.createRoommateLoading = false;
        state.createRoommateSuccess = false;
        state.createRoommateError = 'Error';
      })
      .addCase(updateRoommateProfile.pending, (state) => {
        state.updateRoommateProfileLoading = true;
        state.updateRoommateProfileSuccess = false;
        state.updateRoommateProfileError = null;
      })
      .addCase(updateRoommateProfile.fulfilled, (state) => {
        state.updateRoommateProfileLoading = false;
        state.updateRoommateProfileSuccess = true;
        state.updateRoommateProfileError = null;
      })
      .addCase(updateRoommateProfile.rejected, (state) => {
        state.updateRoommateProfileLoading = false;
        state.updateRoommateProfileSuccess = false;
        state.updateRoommateProfileError = 'Error';
      })
      .addCase(onChangeAvailableRoommateProfile.pending, (state) => {
        state.updateRoommateProfileLoading = true;
        state.successAvailable = false;
        state.errorAvailable = null;
      })
      .addCase(onChangeAvailableRoommateProfile.fulfilled, (state) => {
        state.updateRoommateProfileLoading = false;
        state.successAvailable = true;
        state.errorAvailable = null;
      })
      .addCase(onChangeAvailableRoommateProfile.rejected, (state) => {
        state.updateRoommateProfileLoading = false;
        state.successAvailable = false;
        state.errorAvailable = 'Error';
      })
      .addCase(deleteRoommateProfile.pending, (state) => {
        state.successDelete = false;
        state.errorDelete = null;
      })
      .addCase(deleteRoommateProfile.fulfilled, (state) => {
        state.successDelete = true;
        state.errorDelete = null;
      })
      .addCase(deleteRoommateProfile.rejected, (state) => {
        state.successDelete = false;
        state.errorDelete = 'Error';
      })
      .addCase(getRoommatesPreviewAll.pending, (state) => {
        state.hasErrorPreviewAll = false;
        state.isLoadingPreviewAll = true;
      })
      .addCase(getRoommatesPreviewAll.fulfilled, (state, action) => {
        state.hasErrorPreviewAll = false;
        state.isLoadingPreviewAll = false;
        state.roommatesPreviewAll = action.payload.lastRoommateProfiles;
      })
      .addCase(getRoommatesPreviewAll.rejected, (state) => {
        state.hasErrorPreviewAll = true;
        state.isLoadingPreviewAll = false;
        state.roommatesPreviewAll = [];
      });
  },
});

export const {
  setRoommates,
  setRoommatesLoading,
  setFilters,
  resetSelectedRoommate,
  setFiltersIsApplied,
  resetCreateRoommate,
  resetRoommateProfileError,
  resetRoommateProfileSuccess,
  resetErrorAvailableRoommateProfile,
  resetSuccessAvailableRoommateProfile,
  resetErrorDeleteRoommateProfile,
  resetErrorRoommatesPreviewAll,
} = roommatesSlice.actions;

export default roommatesSlice.reducer;
