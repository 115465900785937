import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createUserAPI, getUserAPI, updateUserAPI } from 'api/user';
import { getAuth, signOut } from 'firebase/auth';
import { RootState } from 'store';

interface UserState {
  data: User<UserRole> | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  updateUser: 'init' | 'loading' | 'succeeded' | 'failed';
}

const initialState: UserState = {
  data: null,
  status: 'idle',
  error: null,
  updateUser: 'init',
};

export const fetchUserDetails = createAsyncThunk(
  'user/fetchUserDetails',
  async (uid: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await getUserAPI(uid);
      const isLoginPageView = window.location.href
        .split('?')[0].split('/').pop() === 'login';
      const isEmployersPageView = window.location.href
        .split('?')[0].split('/').pop() === 'employers';

      if (typeof response === 'number') {
        if (response === 401 ||
          (response === 404 && !isLoginPageView && !isEmployersPageView)) {
          const auth = getAuth();
          try {
            await signOut(auth);
            dispatch(resetUserState());
            return;
          } catch (error) {
            return rejectWithValue('');
          }
        }
        if (response === 404) {
          return 404;
        }
      } else {
        return response;
      }
    } catch (error) {
      return rejectWithValue('');
    }
  }
);

export const updateUserDetails = createAsyncThunk(
  'user/updateUserDetails',
  async (data: Partial<User<UserRole>>, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await updateUserAPI<UserRole>(data);
      if (response.status === 204) {
        const { user } = (getState() as RootState).auth;
        dispatch(fetchUserDetails(user?.uid || ''));
      }
      return;
    } catch (error) {
      return rejectWithValue('');
    }
  }
);

export const createNewUser = createAsyncThunk(
  'user/createUser',
  async (
    data: CreateUser<UserPersonalData | CreateUserRecruiterPartData>,
    { rejectWithValue, dispatch }) => {
    try {
      const responseOk = await createUserAPI<
        UserPersonalData | CreateUserRecruiterPartData>(data);
      if (responseOk) {
        dispatch(fetchUserDetails(data.userId));
        return;
      }
    } catch (error) {
      return rejectWithValue('');
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUserState: (state) => {
      localStorage.clear();
      Object.assign(state, initialState);
    },
    handleUpdateUserData: (state, action: PayloadAction<User<UserRole>>) => {
      state.data = action.payload;
    },
    resetUpdateUserStatus: (state) => {
      state.updateUser = 'init';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createNewUser.fulfilled, (state) => {
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(createNewUser.rejected, (state, action) => {
        state.status = 'failed';
        state.data = null;
        state.error = action.error.message || 'Failed to create new user';
      })
      .addCase(fetchUserDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserDetails.fulfilled, (
        state, action) => {
        state.status = 'succeeded';
        state.error = null;
        if (typeof action.payload !== 'number' && action.payload !== undefined) {
          state.data = action.payload as User<UserRole>;
        }
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.data = null;
        state.error = action.error.message || 'Failed to fetch user details';
      })
      .addCase(updateUserDetails.pending, (state) => {
        state.status = 'loading';
        state.updateUser = 'loading';
      })
      .addCase(updateUserDetails.fulfilled, (state) => {
        state.status = 'succeeded';
        state.error = null;
        state.updateUser = 'succeeded';
      })
      .addCase(updateUserDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to update user details';
        state.updateUser = 'failed';
      })
      .addMatcher(
        (action): action is PayloadAction<boolean> => action.type === 'auth/setLoading',
        (state, action) => {
          state.status = action.payload ? 'loading' : 'idle';
        }
      );
  },
});

export const {
  resetUserState,
  handleUpdateUserData,
  resetUpdateUserStatus,
} = userSlice.actions;

export default userSlice.reducer;
