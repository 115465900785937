import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

type SecondaryButtonProps = {
  classNames?: string;
  text: string;
  blue?: boolean;
  icon?: JSX.Element;
  smallText?: boolean;
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

function SecondaryButton({
  classNames,
  text,
  blue,
  icon,
  smallText,
  ...props
}: SecondaryButtonProps) {

  const colors = blue ?
    ' text-blue-700 border-blue-700 hover:bg-blue-light2' +
    ' disabled:text-blue-600 disabled:border-blue-600' :
    ' text-grey-700 border-grey-700 hover:bg-grey-200' +
    ' disabled:text-grey-600 disabled:border-grey-600';
  const textSizes = smallText ? ' text-sm' : ' text-lg';

  return (
    <button
      {...props}
      className={'text-lg font-bold border h-fit rounded-[50px] px-6 py-2' +
        '  transition-colors duration-300 ease-in-out disabled:cursor-not-allowed' +
        ' disabled:hover:bg-white flex flex-row items-center justify-center gap-3' +
        colors + textSizes + ` ${classNames ? classNames : ''}`}
    >
      {icon}
      {text}
    </button>
  );
}

export default SecondaryButton;
