import React, { Component, ReactNode } from 'react';
import ErrorBoundaryDescription from './ErrorBoundaryDescription';
import ErrorBoundaryTitle from './ErrorBoundaryTitle';
import { RootState } from 'store';
import { connect } from 'react-redux';
import moment from 'moment';

interface ErrorBoundaryProps {
  children?: ReactNode;
  error?: boolean;
  reduxState?: RootState;
  childComponentName: string;
}

interface ErrorBoundaryState {
  hasError: boolean;
  errorInfo?: string;
  componentStack?: string | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      errorInfo: undefined,
      componentStack: undefined
    };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, errorInfo: error.stack };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ componentStack: errorInfo.componentStack }); // Set the componentStack
    const { reduxState, childComponentName } = this.props;

    if (process.env.REACT_APP_FIREBASE_PROJECT_ID === 'eu4ua-83899') {
      const sendErrorInfo = async () => {
        const postData = {
          environment: 'production',
          time: moment().toISOString(),
          error: error.toString(),
          stackTrace: errorInfo.componentStack,
          componentStack: errorInfo.componentStack,
          url: window.location.href,
          reduxState,
          childComponentName,
        };

        return await fetch(`${process.env.REACT_APP_ERROR_WEBHOOK}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(postData),
        });
      };

      sendErrorInfo();
    }
  }

  render() {
    const { hasError } = this.state;
    const { error = false, children } = this.props;

    return (hasError || error) ? (
      <div className={'mx-auto my-14 flex h-fit w-full max-w-[445px]' +
        ' flex-col items-center justify-center'}>
        <div className="flex flex-col items-center justify-center gap-4">
          <ErrorBoundaryTitle />
          <ErrorBoundaryDescription />
        </div>
      </div>
    ) : children;
  }
}

const mapStateToProps = (state: RootState) => ({
  reduxState: state,
});

export default connect(mapStateToProps)(ErrorBoundary);
