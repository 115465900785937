const rxOne = /^[\],:{}\s]*$/;
const rxTwo = /\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g;
const rxThree = /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g;
const rxFour = /(?:^|:|,)(?:\s*\[)+/g;

export const parseResponseJSON = async (response: Response, res = {}) => {
  const isJSON = (input: string) => !!(
    input?.length && rxOne.test(
      input.replace(rxTwo, '@')
        .replace(rxThree, ']')
        .replace(rxFour, '')
    )
  );
  const text = await response.text();

  return isJSON(text) ? JSON.parse(text) : res;
};
