import { useErrorBoundaryTranslations } from './translations';

function ErrorBoundaryTitle() {
  const translate = useErrorBoundaryTranslations();

  return (
    <h1 className="text-center text-3xl font-bold text-grey-900">
      {translate.title}
    </h1>
  );
}

export default ErrorBoundaryTitle;
