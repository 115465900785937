import { Navigate, Route } from 'react-router-dom';
import { appUrls } from 'router';

//redirect urls
const redirectDashboardUrls: string[] = [
  '/home',
  '/refugee-account-deletion',
  '/guest-account-deletion',
  '/host-account-deletion',
  '/refugees',
  '/refugees/refugees-home',
  '/refugees/home',
  '/refugee',
  '/guest',
  '/hosts/home',
  '/host/home',
  '/host/host-home',
  '/host-home',
  '/host',
  '/hosts',
  '/host1',
  '/refugees-family-info',
  '/refugees-employment-info',
  '/refugees-dashboard',
  '/refugee-listing-accommodations',
  '/refugee-listing-jobs',
  '/refugees-home',
  '/refugees-job-home',
  '/host-dashboard',
  '/host-accommodation-registration',
  '/host-accommodation-available',
  '/how-to',
  '/our-partners',
  '/legals',
  '/host-accommodation-not-available',
  '/email-verification',
  '/email-verification-success',
];

const redirectToDashboard = (locale: string) => redirectDashboardUrls
  .map((url: string) => (
    <Route
      key={url}
      path={url}
      element={<Navigate replace to={appUrls.getDashboardPageUrl(locale)} />}
    />
  ));

const redirectRemoteJobsUrls: string[] = [
  '/jobs-advertisment',
  '/recruiters',
  '/recruiters-dashboard',
  '/recruiters-company-registration',
  '/recruiters-job-registration',
];

const redirectToRemoteJobs = (locale: string) => redirectRemoteJobsUrls
  .map((url: string) => (
    <Route
      key={url}
      path={url}
      element={<Navigate replace to={appUrls.getRemoteJobsInfoPageUrl(locale)} />}
    />
  ));

const redirectFromRegisterUrls: string[] = [
  '/en/register',
  '/es/register',
  '/fr/register',
  '/it/register',
  '/uk/register',
];

const redirectFromRegister = (locale: string) => redirectFromRegisterUrls
  .map((url: string) => (
    <Route
      key={url}
      path={url}
      element={<Navigate replace to={appUrls.getLoginPageUrl(locale)} />}
    />
  ));

const redirectFromNotLocalizationUrls = (locale: string) => ([
  <Route
    key="/"
    path="/"
    element={<Navigate replace to={appUrls.getHomePageUrl(locale)} />}
  />,
  <Route
    key="/register"
    path="/register"
    element={<Navigate replace to={appUrls.getLoginPageUrl(locale)} />}
  />,
  <Route
    key="/login"
    path="/login"
    element={<Navigate replace to={appUrls.getLoginPageUrl(locale)} />}
  />,
  <Route
    key="/info-roommates"
    path="/info-roommates"
    element={<Navigate replace to={appUrls.getRoommatesInfoPageUrl(locale)} />}
  />,
  <Route
    key="/info-accommodations"
    path="/info-accommodations"
    element={<Navigate replace to={appUrls.getAccommodationsInfoPageUrl(locale)} />}
  />,
  <Route
    key="/dashboard"
    path="/dashboard"
    element={<Navigate replace to={appUrls.getDashboardPageUrl(locale)} />}
  />,
  <Route
    key="/coliving-for-refugees"
    path="/coliving-for-refugees"
    element={<Navigate replace to={appUrls.getColivingForRefugeesPageUrl(locale)} />}
  />,
  <Route
    key="/free-housing-for-refugees"
    path="/free-housing-for-refugees"
    element={<Navigate replace to={appUrls.getFreeHousingForRefugeesPageUrl(locale)} />}
  />,
  <Route
    key="/about-us"
    path="/about-us"
    element={<Navigate replace to={appUrls.getAboutUsPageUrl(locale)} />}
  />,
  <Route
    key="/contact-us"
    path="/contact-us"
    element={<Navigate replace to={appUrls.getContactUsPageUrl(locale)} />}
  />,
  <Route
    key="/donations"
    path="/donations"
    element={<Navigate replace to={appUrls.getDonationsPageUrl(locale)} />}
  />,
  <Route
    key="/useful-links"
    path="/useful-links"
    element={<Navigate replace to={appUrls.getUsefulLinksPageUrl(locale)} />}
  />,
  <Route
    key="/press"
    path="/press"
    element={<Navigate replace to={appUrls.getPressPageUrl(locale)} />}
  />,
  <Route
    key="/faq"
    path="/faq"
    element={<Navigate replace to={appUrls.getFAQPageUrl(locale)} />}
  />,
  <Route
    key="/safety-tips"
    path="/safety-tips"
    element={<Navigate replace to={appUrls.getSafetyTipsPageUrl(locale)} />}
  />,
  <Route
    key="/responsible-disclosure-policy"
    path="/responsible-disclosure-policy"
    element={<Navigate replace to={appUrls.getResponsibleDisclosurePolicyPageUrl(locale)} />}
  />,
  <Route
    key="/cookies"
    path="/cookies"
    element={<Navigate replace to={appUrls.getCookiesPageUrl(locale)} />}
  />,
  <Route
    key="/privacy-policy-refugees"
    path="/privacy-policy-refugees"
    element={<Navigate replace to={appUrls.getPrivacyPolicyRefugeesPageUrl(locale)} />}
  />,
  <Route
    key="/terms-and-conditions-refugees"
    path="/terms-and-conditions-refugees"
    element={<Navigate replace to={appUrls.getTermsOfUseRefugeesPageUrl(locale)} />}
  />,
  <Route
    key="/privacy-policy-host"
    path="/privacy-policy-host"
    element={<Navigate replace to={appUrls.getPrivacyPolicyHostsPageUrl(locale)} />}
  />,
  <Route
    key="/terms-of-use-hosts"
    path="/terms-of-use-hosts"
    element={<Navigate replace to={appUrls.getTermsOfUseHostsPageUrl(locale)} />}
  />,
  <Route
    key="/jobs"
    path="/jobs"
    element={<Navigate replace to={appUrls.getJobsBrowsingPageUrl(locale)} />}
  />,
  <Route
    key="/remote-jobs-for-ukrainians"
    path="/remote-jobs-for-ukrainians"
    element={<Navigate replace to={appUrls.getRemoteJobsInfoPageUrl(locale)} />}
  />,
  <Route
    key="/jobs-articles/find-a-job-in-germany-jobs-for-ukrainians"
    path="/jobs-articles/find-a-job-in-germany-jobs-for-ukrainians"
    element={<Navigate replace to={appUrls.getRemoteJobGermanyPageUrl(locale)} />}
  />,
  <Route
    key="/jobs-articles/find-a-job-in-spain-jobs-for-ukrainians"
    path="/jobs-articles/find-a-job-in-spain-jobs-for-ukrainians"
    element={<Navigate replace to={appUrls.getRemoteJobSpainPageUrl(locale)} />}
  />,
  <Route
    key="/jobs-articles/find-a-job-in-france-jobs-for-ukrainians"
    path="/jobs-articles/find-a-job-in-france-jobs-for-ukrainians"
    element={<Navigate replace to={appUrls.getRemoteJobFrancePageUrl(locale)} />}
  />,
  <Route
    key="/jobs-articles/find-a-job-in-europe-jobs-for-ukrainians"
    path="/jobs-articles/find-a-job-in-europe-jobs-for-ukrainians"
    element={<Navigate replace to={appUrls.getRemoteJobEuropePageUrl(locale)} />}
  />,
  <Route
    key="/under-maintenance"
    path="/under-maintenance"
    element={<Navigate replace to={appUrls.getDashboardPageUrl(locale)} />}
  />,
  <Route
    key="/info-mentorship"
    path="/info-mentorship"
    element={<Navigate replace to={appUrls.getMentorshipPageUrl(locale)} />}
  />,
  <Route
    key="/info-refugee-mentorship"
    path="/info-refugee-mentorship"
    element={<Navigate replace to={appUrls.getMentorshipInfoRefugeePageUrl(locale)} />}
  />,
  <Route
    key="/info-helper-mentorship"
    path="/info-helper-mentorship"
    element={<Navigate replace to={appUrls.getMentorshipInfoHelperPageUrl(locale)} />}
  />,
  <Route
    key="/stories/tetjana"
    path="/stories/tetjana"
    element={<Navigate replace to={appUrls.getStoriesTetianaUrl(locale)} />}
  />,
  // ===== private urls ===== //
  <Route
    key="/dashboard"
    path="/dashboard"
    element={<Navigate replace to={appUrls.getDashboardPageUrl(locale)} />}
  />,
  <Route
    key="/profile"
    path="/profile"
    element={<Navigate replace to={appUrls.getProfileSettingsPageUrl(locale)} />}
  />,
  <Route
    key="/mentors"
    path="/mentors"
    element={<Navigate replace to={appUrls.getMentorBrowsingPageUrl(locale)} />}
  />,
  <Route
    key="/accommodation-create"
    path="/accommodation-create"
    element={<Navigate replace to={appUrls.getAccommodationCreatePageUrl(locale)} />}
  />,
  <Route
    key="/accommodations"
    path="/accommodations"
    element={<Navigate replace to={appUrls.getAccommodationsBrowsingPageUrl(locale)} />}
  />,
  <Route
    key="/accommodations/:id"
    path="/accommodations/:id"
    element={<Navigate replace to={appUrls.getAccommodationReviewPageUrl(locale)
      .replace(':id', window.location.href.split('/').reverse()[0])} />}
  />,
  <Route
    key="/roommates"
    path="/roommates"
    element={<Navigate replace to={appUrls.getRoommatesBrowsingPageUrl(locale)} />}
  />,
  <Route
    key="/roommates/:id"
    path="/roommates/:id"
    element={<Navigate replace to={appUrls.getRoommateReviewPageUrl(locale)
      .replace(':id', window.location.href.split('/').reverse()[0])} />}
  />,
]);

export const redirectedAppRoutes = (locale: string) => ([
  <Route
    key="/employers"
    path="/employers"
    element={<Navigate replace to={appUrls.getRoommatesInfoPageUrl(locale)} />}
  />,
  <Route
    key="/roommates-info"
    path="/roommates-info"
    element={<Navigate replace to={appUrls.getRoommatesInfoPageUrl(locale)} />}
  />,
  <Route
    key="/accommodations-info"
    path="/accommodations-info"
    element={<Navigate replace to={appUrls.getAccommodationsInfoPageUrl(locale)} />}
  />,
  <Route
    key="/about-platform"
    path="/about-platform"
    element={<Navigate replace to={appUrls.getAboutUsPageUrl(locale)} />}
  />,
  <Route
    key="/about-us-old"
    path="/about-us-old"
    element={<Navigate replace to={appUrls.getAboutUsPageUrl(locale)} />}
  />,
  <Route
    key="/about_us"
    path="/about_us"
    element={<Navigate replace to={appUrls.getAboutUsPageUrl(locale)} />}
  />,
  <Route
    key="/refugees-registration"
    path="/refugees-registration"
    element={<Navigate replace to={appUrls.getLoginPageUrl(locale)} />}
  />,
  <Route
    key="/host-registration"
    path="/host-registration"
    element={<Navigate replace to={appUrls.getLoginPageUrl(locale)} />}
  />,
  <Route
    key="/cookie_policy"
    path="/cookie_policy"
    element={<Navigate replace to={appUrls.getCookiesPageUrl(locale)} />}
  />,
  <Route
    key="/volunteer"
    path="/volunteer"
    element={<Navigate replace to={appUrls.getContactUsPageUrl(locale)} />}
  />,
  <Route
    key="/contact"
    path="/contact"
    element={<Navigate replace to={appUrls.getContactUsPageUrl(locale)} />}
  />,
  <Route
    key="/responsible_disclosure_policy"
    path="/responsible_disclosure_policy"
    element={<Navigate replace to={appUrls.getResponsibleDisclosurePolicyPageUrl(locale)} />}
  />,
  <Route
    key="/useful-links-with-filtering"
    path="/useful-links-with-filtering"
    element={<Navigate replace to={appUrls.getUsefulLinksPageUrl(locale)} />}
  />,
  ...redirectToDashboard(locale),
  ...redirectToRemoteJobs(locale),
  ...redirectFromNotLocalizationUrls(locale),
  ...redirectFromRegister(locale),
]);
