import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import accommodationsHelperSlice from './slices/accommodationsHelperSlice';
import accommodationsRefugeeSlice from './slices/accommodationsRefugeeSlice';
import authSlice from './slices/authSlice';
import mentorSlice from './slices/mentorSlice';
import messagingSlice from './slices/messagingSlice';
import recruiterJobsSlice from './slices/recruiterJobsSlice';
import remoteJobsSlice from './slices/remoteJobsSlice';
import roommatesRefugeeSlice from './slices/roommatesRefugeeSlice';
import { useDispatch } from 'react-redux';
import userSlice from './slices/userSlice';

const store = configureStore({
  reducer: {
    auth: authSlice,
    user: userSlice,
    mentor: mentorSlice,
    messaging: messagingSlice,
    accommodationsRefugee: accommodationsRefugeeSlice,
    accommodationsHelper: accommodationsHelperSlice,
    roommatesRefugee: roommatesRefugeeSlice,
    remoteJobs: remoteJobsSlice,
    recruiterJobs: recruiterJobsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['your/action/type'],
        ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
        ignoredPaths: ['items.dates'],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>; // тип для доступа к состоянию стора
export type AppDispatch = typeof store.dispatch; // тип для доступа к диспатчу
export type AppThunk<ReturnType = void> =
  ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
