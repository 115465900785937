import { Navigate, useLocation } from 'react-router-dom';
import PageLoader from 'components/PageLoader';
import { appUrls } from 'router';
import { useAuthUserStatus } from 'hooks/useAuthUserStatus';
import { useLocaleContext } from 'hooks/useLocaleContext';

function PrivateRouteGuard({ children }: { children: JSX.Element }): JSX.Element {
  const location = useLocation();
  const { locale } = useLocaleContext();
  const { isAuthenticated, isEmailVerified, isLoading } = useAuthUserStatus();

  if (isLoading) {
    return (
      <PageLoader />
    );
  }

  if (isAuthenticated && isEmailVerified) {
    return (
      <>{children}</>
    );
  }

  if (isAuthenticated && !isEmailVerified) {
    return (
      <Navigate replace to={appUrls.getLoginPageUrl(locale)} state={{ from: location }} />
    );
  }

  return (
    <Navigate replace to={appUrls.getHomePageUrl(locale)} state={{ from: location }} />
  );
}

export default PrivateRouteGuard;
