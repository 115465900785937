import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createRefugeeAccomodationProfileAPI,
  getAccommodationByIdAPI,
  getAccommodationsAPI,
  getAccommodationsForMapAPI,
  updateRefugeeAccomodationProfileAPI,
} from 'api/accommodationsRefugee';

interface AccommodationsState {
  accommodations: RefugeeAccommodation[]
  filters: Partial<AccommodationFilters>;
  accommodationsLoading: boolean;
  selectedAccommodation: RefugeeAccommodation | null;
  totalCount: number | null;
  currentPage: number;
  totalPages: number;
  filtersIsApplied: boolean;
  updateAccommodationProfileLoading: boolean;
  updateAccommodationProfileError: string | null;
  updateAccommodationProfileSuccess: boolean;
  createAccommodationProfileLoading: boolean;
  createAccommodationProfileError: string | null;
  createAccommodationProfileSuccess: boolean;
}

export const initialState: AccommodationsState = {
  accommodations: [],
  filters: {},
  accommodationsLoading: false,
  selectedAccommodation: null,
  totalCount: 0,
  currentPage: 1,
  totalPages: 0,
  filtersIsApplied: false,
  updateAccommodationProfileLoading: false,
  updateAccommodationProfileError: null,
  updateAccommodationProfileSuccess: false,
  createAccommodationProfileLoading: false,
  createAccommodationProfileError: null,
  createAccommodationProfileSuccess: false,
};

export const getAccommodations = createAsyncThunk(
  'user/getAccommodations',
  async (data: AccommodationRefugeeRequest, { rejectWithValue }) => {
    try { 
      const response = await getAccommodationsAPI(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAccommodationsOnMap = createAsyncThunk(
  'user/getAccommodationsOnMap',
  async (filters: Partial<HelperAccommodationRequest>, { rejectWithValue }) => {
    try {
      const response = await getAccommodationsForMapAPI(filters);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAccommodationById = createAsyncThunk(
  'user/getAccommodationById',
  async function (id: string, { rejectWithValue }) {
    try {
      const response = await getAccommodationByIdAPI(id);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createRefugeeAccomodationProfile = createAsyncThunk(
  'refugee/createRefugeeAccomodationProfile',
  async function (data: Partial<FamilyInfo>, { rejectWithValue }) {
    try {
      return await createRefugeeAccomodationProfileAPI(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateRefugeeAccomodationProfile = createAsyncThunk(
  'refugee/updateRefugeeAccomodationProfile',
  async function (data: Partial<FamilyInfo>, { rejectWithValue }) {
    try {
      return await updateRefugeeAccomodationProfileAPI(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const accommodationsRefugeeSlice = createSlice({
  name: 'accommodations',
  initialState,
  reducers: {
    setAccommodations: (state, action: PayloadAction<AccommodationsState['accommodations']>) => {
      state.accommodations = action.payload;
    },
    setAccommodationsLoading: (state, action: PayloadAction<boolean>) => {
      state.accommodationsLoading = action.payload;
    },
    setFilters: (state, action: PayloadAction<{}>) => {
      state.filters = action.payload;
    },
    resetSelectedAccommodation: (state) => {
      state.selectedAccommodation = null;
    },
    setFiltersIsApplied: (state, action: PayloadAction<boolean>) => {
      state.filtersIsApplied = action.payload;
    },
    resetAccommodationProfileError(state) {
      state.updateAccommodationProfileError = null;
      state.createAccommodationProfileError = null;
    },
    resetAccommodationProfileSuccess(state) {
      state.updateAccommodationProfileSuccess = false;
      state.createAccommodationProfileSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccommodations.pending, (state) => {
        state.accommodations = [];
        state.accommodationsLoading = true;
        state.currentPage = 1;
        state.totalPages = 0;
      })
      .addCase(getAccommodations.fulfilled,
        (state, action: PayloadAction<AccommodationsResponse |
           Partial<AccommodationsResponse>>) => {
          state.accommodationsLoading = false;
          state.accommodations = action.payload?.allAccommodations ?? [];
          state.totalCount = action.payload?.totalCount ?? 0;
          state.currentPage = action.payload?.currentPage ?? 1;
          state.totalPages = action.payload?.totalPages ?? 0;
        })
      .addCase(getAccommodations.rejected, (state) => {
        state.accommodationsLoading = false;
        state.accommodations = [];
        state.totalCount = null;
        state.currentPage = 1;
        state.totalPages = 0;
      })
      .addCase(getAccommodationsOnMap.pending, (state) => {
        state.accommodationsLoading = true;
        state.accommodations = [];
      })
      .addCase(getAccommodationsOnMap.fulfilled,
        (state, action: PayloadAction<AccommodationsMapResponse>) => {
          state.accommodationsLoading = false;
          state.accommodations = action.payload.allAccommodations;
          state.totalCount = action.payload.totalCount;
        })
      .addCase(getAccommodationsOnMap.rejected, (state) => {
        state.accommodationsLoading = false;
        state.accommodations = [];
        state.totalCount = 0;
      })
      .addCase(getAccommodationById.pending, (state) => {
        state.accommodationsLoading = true;
      })
      .addCase(getAccommodationById.fulfilled,
        (state, action: PayloadAction<RefugeeAccommodation | null>) => {
          state.accommodationsLoading = false;
          state.selectedAccommodation = action.payload;
        })
      .addCase(getAccommodationById.rejected, (state) => {
        state.accommodationsLoading = false;
      })
      .addCase(createRefugeeAccomodationProfile.pending, (state) => {
        state.createAccommodationProfileLoading = true;
        state.createAccommodationProfileError = null;
        state.createAccommodationProfileSuccess = false;
      })
      .addCase(createRefugeeAccomodationProfile.fulfilled, (state) => {
        state.createAccommodationProfileLoading = false;
        state.createAccommodationProfileError = null;
        state.createAccommodationProfileSuccess = true;
      })
      .addCase(createRefugeeAccomodationProfile.rejected, (state,) => {
        state.createAccommodationProfileLoading = false;
        state.createAccommodationProfileError = 'Error';
        state.createAccommodationProfileSuccess = false;
      })
      .addCase(updateRefugeeAccomodationProfile.pending, (state) => {
        state.updateAccommodationProfileLoading = true;
        state.updateAccommodationProfileError = null;
        state.updateAccommodationProfileSuccess = false;
      })
      .addCase(updateRefugeeAccomodationProfile.fulfilled, (state) => {
        state.updateAccommodationProfileLoading = false;
        state.updateAccommodationProfileError = null;
        state.updateAccommodationProfileSuccess = true;
      })
      .addCase(updateRefugeeAccomodationProfile.rejected, (state,) => {
        state.updateAccommodationProfileLoading = false;
        state.updateAccommodationProfileError = 'Error';
        state.updateAccommodationProfileSuccess = false;
      });
  },
});

export const {
  setAccommodations,
  setAccommodationsLoading,
  setFilters,
  resetSelectedAccommodation,
  setFiltersIsApplied,
  resetAccommodationProfileError,
  resetAccommodationProfileSuccess,
} = accommodationsRefugeeSlice.actions;

export default accommodationsRefugeeSlice.reducer;
