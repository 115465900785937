import { lazy } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const lazyWithRetry = (componentImport: any, name: string) => lazy(
  async () => {
    const pageHasAlreadyBeenForceRefreshed = window.localStorage
      .getItem('page-has-been-force-refreshed_' + name);

    try {
      const component = await componentImport();
      if (pageHasAlreadyBeenForceRefreshed) window.localStorage
        .removeItem('page-has-been-force-refreshed_' + name);
      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        window.localStorage.setItem('page-has-been-force-refreshed_' + name, 'true');
        return window.location.reload();
      }
      throw error;
    }
  }
);
