import { RootState } from 'store';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

interface ProtectedRouteByRoleProps {
  children: React.ReactNode;
  protectedRole: UserRole;
}

function ProtectedRouteByRole({ children, protectedRole }: ProtectedRouteByRoleProps) {
  const user = useSelector((state: RootState) => state.user.data);
  const loading = useSelector((state: RootState) => state.user.status) === 'loading';
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && (!user || user.role !== protectedRole)) {
      navigate(-1);
    }
  }, [user, navigate]);

  return <>{children}</>;
}

export default ProtectedRouteByRole;
