import PageLoader from 'components/PageLoader';
import PrivateRouteGuard from 'router/guards/PrivateRouteGuard';
import ProtectedRouteByRole from 'router/guards/ProtectedRouteByRole';
import { Suspense } from 'react';
import { appUrls } from './appUrls';
import { lazyWithRetry } from 'utils/lazyWithRetry';

// publicRoutes
const HomePage = lazyWithRetry(
  () => import('../../pages/home'), 'HomePage');
const LoginPage = lazyWithRetry(
  () => import('../../pages/login'), 'LoginPage');
const SignUpAsRecruiterPage = lazyWithRetry(
  () => import('../../pages/recruiterPages/signUpAsRecruiter'), 'SignUpAsRecruiterPage');
const RoommatesInfo = lazyWithRetry(
  () => import('../../pages/refugeePages/roommatesInfo'), 'RoommatesInfo');
const AccommodationsInfo = lazyWithRetry(
  () => import('../../pages/refugeePages/accommodationsInfo'), 'AccommodationsInfo');
// const MentorsInfo = lazyWithRetry(
//   () => import('../../pages/refugeePages/mentorsInfo'), 'MentorsInfo');
const RemoteJob = lazyWithRetry(
  () => import('../../pages/footerPages/findJobs/RemoteJob'), 'RemoteJob');
const Coliving = lazyWithRetry(
  () => import('../../pages/footerPages/housingForRefugees/Coliving'), 'Coliving');
const FreeHousing = lazyWithRetry(
  () => import('../../pages/footerPages/housingForRefugees/FreeHousing'), 'FreeHousing');
const Donations = lazyWithRetry(
  () => import('../../pages/footerPages/donations'), 'Donations');
const AboutUs = lazyWithRetry(
  () => import('../../pages/footerPages/aboutUs'), 'AboutUs');
const ContactUs = lazyWithRetry(
  () => import('../../pages/footerPages/contactUs'), 'ContactUs');
const UsefulLinks = lazyWithRetry(
  () => import('../../pages/footerPages/usefulLinks'), 'UsefulLinks');
const Press = lazyWithRetry(
  () => import('../../pages/footerPages/press'), 'Press');
const FAQ = lazyWithRetry(
  () => import('../../pages/footerPages/faq/FAQ'), 'FAQ');
const SafetyTips = lazyWithRetry(
  () => import('../../pages/footerPages/faq/SafetyTips'), 'SafetyTips');
const ResponsibleDisclosurePolicy = lazyWithRetry(
  () => import(
    '../../pages/footerPages/policies/ResponsibleDisclosurePolicy'),
  'ResponsibleDisclosurePolicy',
);
const CookiePolicy = lazyWithRetry(
  () => import('../../pages/footerPages/policies/CookiePolicy'), 'CookiePolicy');
const PrivacyPolicyRefugees = lazyWithRetry(
  () => import('../../pages/footerPages/policies/PrivacyPolicyRefugees'), 'PrivacyPolicyRefugees');
const TermsOfUseRefugees = lazyWithRetry(
  () => import('../../pages/footerPages/policies/TermsOfUseRefugees'), 'TermsOfUseRefugees');
const PrivacyPolicyHosts = lazyWithRetry(
  () => import('../../pages/footerPages/policies/PrivacyPolicyHosts'), 'PrivacyPolicyHosts');
const TermsOfUseHosts = lazyWithRetry(
  () => import('../../pages/footerPages/policies/TermsOfUseHosts'), 'TermsOfUseHosts');
const FindAJob = lazyWithRetry(
  () => import('../../pages/footerPages/findJobs/FindAJob'), 'FindAJob');
const MentorshipInfoHelper = lazyWithRetry(
  () => 
    import('../../pages/footerPages/mentorship/MentorshipInfoHelper'), 'MentorshipInfoHelper');
const MentorshipInfoRefugee = lazyWithRetry(
  () =>
    import('../../pages/footerPages/mentorship/MentorshipInfoRefugee'), 'MentorshipInfoRefugee');

// privateRoutes
const DashboardPage = lazyWithRetry(
  () => import('../../pages/dashboard'), 'DashboardPage');
const ProfilePage = lazyWithRetry(
  () => import('../../pages/profile'), 'ProfilePage');
const AccommodationCreate = lazyWithRetry(
  () => import('../../pages/helperPages/accommodationCreate'), 'AccommodationCreate');
const AccommodationsBrowsing = lazyWithRetry(
  () => import('../../pages/refugeePages/accommodationsBrowsing'), 'AccommodationsBrowsing');
const AccommodationReview = lazyWithRetry(
  () => import('../../pages/refugeePages/accommodationReview'), 'AccommodationReview');
const RoommatesBrowsing = lazyWithRetry(
  () => import('../../pages/refugeePages/roommatesBrowsing'), 'RoommatesBrowsing');
const RoommateReview = lazyWithRetry(
  () => import('../../pages/refugeePages/roommateReview'), 'RoommateReview');
const JobsBrowsing = lazyWithRetry(
  () => import('../../pages/refugeePages/jobsBrowsing'), 'JobsBrowsing');
const JobReview = lazyWithRetry(
  () => import('../../pages/refugeePages/jobReview'), 'JobReview');
const ManageJobOffers = lazyWithRetry(
  () => import('../../pages/recruiterPages/manageJobOffers'), 'ManageJobOffers');
const MentorBrowsing = lazyWithRetry(
  () => import('../../pages/refugeePages/mentorBrowsing'), 'MentorBrowsing');
const TetianaPage = lazyWithRetry(
  () => import('../../pages/footerPages/peopleStories/TetianaPage'), 'TetianaPage');
const renderLoader = () => <PageLoader />;

const publicRoutes: AppRoute[] = [
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <HomePage />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getHomePageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <LoginPage />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getLoginPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <SignUpAsRecruiterPage />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getSignUpAsRecruiterPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <RoommatesInfo />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getRoommatesInfoPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <AccommodationsInfo />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getAccommodationsInfoPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <RemoteJob />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getRemoteJobsInfoPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <Coliving />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getColivingForRefugeesPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <FreeHousing />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getFreeHousingForRefugeesPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <Donations />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getDonationsPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <AboutUs />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getAboutUsPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <ContactUs />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getContactUsPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <UsefulLinks />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getUsefulLinksPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <Press />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getPressPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <FAQ />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getFAQPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <SafetyTips />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getSafetyTipsPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <ResponsibleDisclosurePolicy />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getResponsibleDisclosurePolicyPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <CookiePolicy />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getCookiesPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <PrivacyPolicyRefugees />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getPrivacyPolicyRefugeesPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <TermsOfUseRefugees />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getTermsOfUseRefugeesPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <PrivacyPolicyHosts />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getPrivacyPolicyHostsPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <TermsOfUseHosts />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getTermsOfUseHostsPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <FindAJob type="Germany" />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getRemoteJobGermanyPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <FindAJob type="Spain" />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getRemoteJobSpainPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <FindAJob type="France" />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getRemoteJobFrancePageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <FindAJob type="Europe" />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getRemoteJobEuropePageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <MentorshipInfoHelper />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getMentorshipInfoHelperPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <MentorshipInfoRefugee />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getMentorshipInfoRefugeePageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <TetianaPage />
      </Suspense>
    ),
    path: (locale: string) => appUrls.getStoriesTetianaUrl(locale),
  },
  
];

const privateRoutes: AppRoute[] = [
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <PrivateRouteGuard>
          <DashboardPage />
        </PrivateRouteGuard>
      </Suspense>
    ),
    path: (locale: string) => appUrls.getDashboardPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <PrivateRouteGuard>
          <ProfilePage />
        </PrivateRouteGuard>
      </Suspense>
    ),
    path: (locale: string) => appUrls.getProfileSettingsPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <PrivateRouteGuard>
          <ProtectedRouteByRole protectedRole="HELPER">
            <AccommodationCreate />
          </ProtectedRouteByRole>
        </PrivateRouteGuard>
      </Suspense>
    ),
    path: (locale: string) => appUrls.getAccommodationCreatePageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <PrivateRouteGuard>
          <ProtectedRouteByRole protectedRole="REFUGEE">
            <AccommodationsBrowsing />
          </ProtectedRouteByRole>
        </PrivateRouteGuard>
      </Suspense>
    ),
    path: (locale: string) => appUrls.getAccommodationsBrowsingPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <PrivateRouteGuard>
          <ProtectedRouteByRole protectedRole="REFUGEE">
            <MentorBrowsing />
          </ProtectedRouteByRole>
        </PrivateRouteGuard>
      </Suspense>
    ),
    path: (locale: string) => appUrls.getMentorBrowsingPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <PrivateRouteGuard>
          <ProtectedRouteByRole protectedRole="REFUGEE">
            <AccommodationReview />
          </ProtectedRouteByRole>
        </PrivateRouteGuard>
      </Suspense>
    ),
    path: (locale: string) => appUrls.getAccommodationReviewPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <PrivateRouteGuard>
          <ProtectedRouteByRole protectedRole="REFUGEE">
            <RoommatesBrowsing />
          </ProtectedRouteByRole>
        </PrivateRouteGuard>
      </Suspense>
    ),
    path: (locale: string) => appUrls.getRoommatesBrowsingPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <PrivateRouteGuard>
          <ProtectedRouteByRole protectedRole="REFUGEE">
            <RoommateReview />
          </ProtectedRouteByRole>
        </PrivateRouteGuard>
      </Suspense>
    ),
    path: (locale: string) => appUrls.getRoommateReviewPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <PrivateRouteGuard>
          <ProtectedRouteByRole protectedRole="REFUGEE">
            <JobsBrowsing />
          </ProtectedRouteByRole>
        </PrivateRouteGuard>
      </Suspense>
    ),
    path: (locale: string) => appUrls.getJobsBrowsingPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <PrivateRouteGuard>
          <ProtectedRouteByRole protectedRole="RECRUITER">
            <ManageJobOffers />
          </ProtectedRouteByRole>
        </PrivateRouteGuard>
      </Suspense>
    ),
    path: (locale: string) => appUrls.getManageJobOffersPageUrl(locale),
  },
  {
    element: (
      <Suspense fallback={renderLoader()}>
        <PrivateRouteGuard>
          <ProtectedRouteByRole protectedRole="REFUGEE">
            <JobReview />
          </ProtectedRouteByRole>
        </PrivateRouteGuard>
      </Suspense>
    ),
    path: (locale: string) => appUrls.getJobReviewPageUrl(locale),
  },
];

export const allRoutes = [
  ...publicRoutes,
  ...privateRoutes,
];
