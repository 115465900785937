import { getAuth } from 'firebase/auth';
import { parseResponseJSON } from 'utils/apiHelper';

const API_BASE = process.env.REACT_APP_API_BASE_URL;

export async function createNewJobAPI(data: CreateVacancy) {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken();

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...data })
  };

  const response = await fetch(
    `${API_BASE}/api/v1/job`,
    requestOptions,
  );

  return response.ok ?
    parseResponseJSON(response, '') :
    Promise.reject(response.status);
}

export async function getMyJobsAPI(
  pagination: PaginationRequest,
): Promise<VacancyResponse> {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken();

  const response = await fetch(`${API_BASE}/api/v1/recruiter/myJobs`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(pagination),
  });

  return response.ok ?
    parseResponseJSON(response) :
    Promise.reject(response.status);
}

export async function deleteMyJobsAPI(ids: string[]) {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken();

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ids })
  };

  const response = await fetch(
    `${API_BASE}/api/v1/job/delete`,
    requestOptions,
  );

  return response.ok ?
    parseResponseJSON(response, '') :
    Promise.reject(response.status);
}

export async function updateJobByIdAPI(data: VacancyUpdateRequest) {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken();

  const { id, ...rest } = data;
  const requestOptions: RequestInit = {
    method: 'PATCH',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...rest })
  };

  const response = await fetch(
    `${API_BASE}/api/v1/recruiter/job/${id}`,
    requestOptions,
  );

  return response.ok ?
    parseResponseJSON(response, '') :
    Promise.reject(response.status);
}

export async function changeAvailableStatusAPI(
  data: VacancyAvailableStatusChangeResponse,
) {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken();

  const requestOptions: RequestInit = {
    method: 'PATCH',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...data })
  };

  const response = await fetch(
    `${API_BASE}/api/v1/recruiter/jobs/activate`,
    requestOptions,
  );

  return response.ok ?
    parseResponseJSON(response, '') :
    Promise.reject(response.status);
}
