import AppRouter from 'router';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import i18n from 'i18n';
import store from './store';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <AppRouter />
      </I18nextProvider>
    </BrowserRouter>
  </Provider>
); 
