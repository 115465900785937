import { Button, PrimaryButton } from 'components/Buttons';
import ErrorBoundary from 'components/ErrorBoundary';
import MainModalContainer from 'layouts/MainModalContainer';
import { appUrls} from 'router/constants';
import { useLocaleContext } from 'hooks/useLocaleContext';
import { useModalTranslations } from '../translations';
  

interface CookiePolicyProps {
  open: string;
  onClose: () => void;
  onAccept: () => void;
}

function CookiePolicy({ open, onClose, onAccept }: CookiePolicyProps) {
  const translate = useModalTranslations();
  const { locale } = useLocaleContext(); 
  const onCancel = () => {
    onClose();
  };
  const onFullClose = () => {
    onAccept();
  };

  return (
    <ErrorBoundary childComponentName="CookiePolicy">
      <MainModalContainer
        open={!!open}
        modalFooter={null}
        limitedHeight={true}
        modalHeader={
          <></>
        }
      >
        <div className={'mx-2 my-6 flex flex-col items-center justify-between h-[77%]' +
          ' desktop:mx-6 desktop:mt-8 desktop: h-[60%]'}>
          <div>
            <p>
              {translate.policyDescription} 
              <a href={appUrls.getCookiesPageUrl(locale)}
                className="text-blue-700 hover:text-blue-800"
              >{' ' + translate.policyDescriptionPlaceholder}</a>.
            </p>
            <p>
              {translate.policyDescriptionSecond}
            </p>
          </div>
              
          <div className="mt-2 flex gap-4">
            <Button
              text={ translate.policyDecline }
              classNames="text-sm font-normal text-blue-700 hover:text-blue-800"
              onClick={() => onCancel()
              }
            />
            <PrimaryButton
              text={translate.policyAccept}
              classNames="text-sm font-normal text-blue-700 hover:text-blue-800"
              onClick={() => onFullClose()}
            />
          </div>
              
        </div>
      </MainModalContainer>
    </ErrorBoundary>
  );
}

export default CookiePolicy;
