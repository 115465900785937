import { getAuth } from 'firebase/auth';

const API_BASE = process.env.REACT_APP_API_BASE_URL;


export async function createMentorProfileAPI(data: createMentorRequest)
  : Promise<boolean> {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken(); 
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions: RequestInit = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${API_BASE}/api/v1/users/mentor-profile`,
    requestOptions,
  );
  return response.ok; 
}

export async function getMentorByIdAPI(id: string): Promise<MentorProfile | null> {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken();

  try {
    const response = await fetch(`${API_BASE}/api/v1/users/mentor-profile/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });

    if (!response.ok) {
      return null;
    }

    const result: MentorProfile = await response.json();
    return result;
  } catch (error) { 
    return null;
  }
}

export async function statusChangeMentorProfileAPI(data: boolean)
  : Promise<boolean> {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken(); 
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions: RequestInit = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  };

  const response = await fetch(
    `${API_BASE}/api/v1/users/mentor-profile/activate?isActive=${data}`,
    requestOptions,
  );
  return response.ok; 
}

export async function getMentorsSearchAPI(data: MentorsSearchRequest)
  : Promise<MentorsResponse | Partial<MentorsResponse>> {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken();
  const raw = JSON.stringify(data);
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  const response = await fetch(
    `${API_BASE}/api/v1/users/mentor-profile/search`,
    requestOptions,
  )
    .then((response: Response) => {
      if (response.status !== 200) {
        throw new Error('Error fetching accommodations');
      }
      return response?.json() || {};
    })
    .then((result: MentorsResponse | Partial<MentorsResponse>) => result);

  return response;
}

export async function deleteMentorProfileAPI()
: Promise<boolean> {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken(); 
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions: RequestInit = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }    
  };

  const response = await fetch(
    `${API_BASE}/api/v1/users/mentor-profile/delete`,
    requestOptions,
  );
  return response.ok; 
}

export async function contactMentorAPI(data: ConteactMentorRequest)
  : Promise<boolean> {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken();
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: data.message,
    redirect: 'follow'
  };

  const response = await fetch(
    `${API_BASE}/api/v1/users/${data.mentorId}/mentor-profile/send-message`,
    requestOptions,
  );
  return response.ok; 
}

