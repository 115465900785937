import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  contactMentorAPI,
  createMentorProfileAPI,
  deleteMentorProfileAPI,
  getMentorByIdAPI,
  getMentorsSearchAPI,
  statusChangeMentorProfileAPI,
} from 'api/mentor'; 
 

interface MentorState {
  loading: boolean;
  error: boolean;
  success: boolean;
  mentors: MentorProfile[];
  selectedMentor: MentorProfile | null;
  filters: Partial<MentorsFilters>;
  filtersIsApplied: boolean;
  mentorsLoading: boolean;
  currentPage: number;
  totalPages: number;
  totalCount: number | null;
}

const initialState: MentorState = {
  loading: false,
  error: false,
  success: false,
  mentors: [],
  selectedMentor: null,
  filters: {},  
  filtersIsApplied: false,
  mentorsLoading: false,
  currentPage: 1,
  totalPages: 0,
  totalCount: null,
};

export const createMentorProfile = createAsyncThunk(
  'mentor/createMentorProfile',
  async (data: createMentorRequest, { rejectWithValue }) => {
    try {
      const response = await createMentorProfileAPI(data);
      if (response) return;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMentorById = createAsyncThunk( 
  'mentor/getMentorById',
  async (data: string, { rejectWithValue }) => {
    try {
      const response: MentorProfile | null = await getMentorByIdAPI(data);
      if(!response) throw new Error('Error');
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const statusChangeMentorProfile = createAsyncThunk(
  'mentor/statusChangeMentorProfile',
  async (data: boolean, { rejectWithValue }) => {
    try {
      const response = await statusChangeMentorProfileAPI(data);
      if (response) return;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMentorsSearch = createAsyncThunk(
  'mentor/getMentorsSearch',
  async (data: MentorsSearchRequest, { rejectWithValue }) => {
    try {
      const response: MentorsResponse | Partial<MentorsResponse>= await getMentorsSearchAPI(data);
      if(!response) throw new Error('Error');
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const deleteMentorProfile = createAsyncThunk(
  'mentor/deleteMentorProfile',
  async (_, { rejectWithValue }) => {
    try {
      const response = await deleteMentorProfileAPI();
      if (response) return;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const contactMentor = createAsyncThunk(
  'mentor/contactMentor',
  async (data: ConteactMentorRequest, { rejectWithValue }) => {
    try {
      const response = await contactMentorAPI(data);
      if (response) return;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const mentorSlice = createSlice({
  name: 'mentor',
  initialState,
  reducers: {
    setMentors: (state, action: PayloadAction<MentorProfile[]>) => {
      state.mentors = action.payload;
    },
    setMentorsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFilters: (state, action: PayloadAction<{}>) => {
      state.filters = action.payload;
    },
    setSelectedMentor: (state, action: PayloadAction<MentorProfile>) => {
      state.selectedMentor = action.payload;
    },
    setActiveMentorContacted: (state) => {
      if(state.selectedMentor) {
        state.selectedMentor.isAlreadyContacted = true;
      }
    },
    resetSelectedMentor: (state) => {
      state.selectedMentor = null;
    },
    setFiltersIsApplied: (state, action: PayloadAction<boolean>) => {
      state.filtersIsApplied = action.payload;
    },
    resetStatus: (state) => {
      state.error = false;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMentorsSearch.pending, (state) => {
        state.mentors = [];
        state.mentorsLoading = true;
        state.currentPage = 1;
        state.totalPages = 0;
      })
      .addCase(getMentorsSearch.fulfilled,
        (state, 
          action: PayloadAction<MentorsResponse | Partial<MentorsResponse>>) => {
          state.mentorsLoading = false;
          state.mentors = action.payload.allMentors || [];
          state.totalCount = action.payload?.totalCount || 0;
          state.currentPage = action.payload.currentPage || 1;
          state.totalPages = action.payload.totalPages || 0;
        })
      .addCase(getMentorsSearch.rejected, (state) => {
        state.mentorsLoading = false;
        state.mentors = [];
        state.totalCount = null;
        state.currentPage = 1;
        state.totalPages = 0;
      })
 
      .addCase(getMentorById.pending, (state) => {
        state.mentorsLoading = true;
      })
      .addCase(getMentorById.fulfilled,
        (state, action) => {
          state.mentorsLoading = false;
          state.selectedMentor = action.payload;
        })
      .addCase(getMentorById.rejected, (state) => {
        state.mentorsLoading = false;
      })

      .addCase(createMentorProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(createMentorProfile.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(createMentorProfile.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      .addCase(statusChangeMentorProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(statusChangeMentorProfile.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(statusChangeMentorProfile.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      .addCase(deleteMentorProfile.pending, (state) => {
        state.loading = true;
      })

      .addCase(deleteMentorProfile.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })

      .addCase(deleteMentorProfile.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      .addCase(contactMentor.pending, (state) => {
        state.loading = true;
      })
      .addCase(contactMentor.fulfilled, (state) => {
        const selectedMentor = state.selectedMentor;
        if (selectedMentor) {
          selectedMentor.isAlreadyContacted = true;
          const mentor = state.mentors.find(mentor => mentor.id === selectedMentor.id);
          if (mentor) {
            mentor.isAlreadyContacted = true;
          }
        }
        state.loading = false;
        state.success = true;
      })
      .addCase(contactMentor.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  }

});


export const {
  setMentors,
  setMentorsLoading,
  setFilters,
  resetSelectedMentor,
  setSelectedMentor,
  setFiltersIsApplied,
  resetStatus,
} = mentorSlice.actions;

export default mentorSlice.reducer;
