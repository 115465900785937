import * as z from 'zod';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  sendContactUsMessageAPI,
  sendRequestToHostAPI,
  sendRequestToRoommateAPI,
} from 'api/messaging';
import { contactUsSchemaForm } from 'data/schemaForm';

type ContactUsData = z.infer<typeof contactUsSchemaForm>;

interface MessagingState {
  loading: boolean;
  error: boolean;
  success: boolean;
}

const initialState: MessagingState = {
  loading: false,
  error: false,
  success: false,
};

export const sendContactUsMessage = createAsyncThunk(
  'messages/sendContactUsMessage',
  async (data: ContactUsData, { rejectWithValue }) => {
    try {
      const response = await sendContactUsMessageAPI(data);
      if (response.status === 204) return;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendRequestToHost = createAsyncThunk(
  'messages/sendRequestToHost',
  async (data: MessageData, { rejectWithValue }) => {
    try {
      const response = await sendRequestToHostAPI(data);
      if (response.status === 204) return;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendRequestToRoommate = createAsyncThunk(
  'messages/sendRequestToRoommate',
  async (data: MessageData, { rejectWithValue }) => {
    try {
      const response = await sendRequestToRoommateAPI(data);
      if (response.status === 204) return;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const messagingSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.loading = false;
      state.error = false;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendContactUsMessage.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(sendContactUsMessage.fulfilled, (state) => {
        state.loading = false;
        state.error = false;
        state.success = true;
      })
      .addCase(sendContactUsMessage.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.success = false;
      })
      .addCase(sendRequestToHost.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(sendRequestToHost.fulfilled, (state) => {
        state.loading = false;
        state.error = false;
        state.success = true;
      })
      .addCase(sendRequestToHost.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.success = false;
      })
      .addCase(sendRequestToRoommate.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(sendRequestToRoommate.fulfilled, (state) => {
        state.loading = false;
        state.error = false;
        state.success = true;
      })
      .addCase(sendRequestToRoommate.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.success = false;
      });
  }
});

export const {
  resetStatus,
} = messagingSlice.actions;

export default messagingSlice.reducer;
