import { useTranslation } from 'react-i18next';

export function useButtonsTranslations() {
  const { t } = useTranslation('translation', { keyPrefix: 'other' });

  return {
    translate: t('translate'),
    backToOriginal: t('backToOriginal'),

  };
}
