import i18next from 'i18next';

interface NavigatorLanguage extends Navigator {
  userLanguage?: string;
  browserLanguage?: string;
}

interface CustomWindow extends Window {
  navigator: NavigatorLanguage;
}

declare const window: CustomWindow;

const getDefaultLanguage = () => {
  const lang = localStorage.getItem('i18nextLng') ?
    (localStorage.getItem('i18nextLng') || 'en') : i18next.language ?
      i18next.language : window.navigator?.language ?
        window.navigator.language : 'en';

  if (lang?.indexOf('-') !== -1) return lang?.split('-')[0];
  if (lang?.indexOf('_') !== -1) return lang?.split('_')[0];
  if (lang === 'ru') return 'uk';
   
  return lang;
};

export const availableLocales = ['en', 'uk', 'fr', 'es', 'it'];
export const defaultLocale = (availableLocales.includes(getDefaultLanguage() || '') ?
  getDefaultLanguage() : 'en') as string;
