import { getAuth } from 'firebase/auth';

const API_BASE = process.env.REACT_APP_API_BASE_URL;

// for getting user data
export async function getUserAPI<T>(uid: string):
  Promise<User<T extends UserRole ? T : never> | number> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User not logged in');
  }
  const token = await user.getIdToken();
  const response = await fetch(`${API_BASE}/api/v1/users/${uid}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });
  if (response.status === 200) {
    return response.json();
  }
  return response.status;
}

// for creating user
export async function createUserAPI<T extends object = UserPersonalData>(
  data: CreateUser<T>,
): Promise<boolean> {
  const response = await fetch(`${API_BASE}/api/v1/users`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...data }),
  }).then((response: Response) => {
    if (response.status !== 201) {
      throw new Error('Error creating user');
    }
    return response.ok;
  });

  return response;
}

// for updating user
export async function updateUserAPI<T>(
  data: Partial<User<T extends UserRole ? T : never>>,
): Promise<Response> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User not logged in');
  }
  const token = await user.getIdToken();

  const response = await fetch(`${API_BASE}/api/v1/users`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data),
  });

  return response;
}

// for deleting a user
export async function deleteUserAPI(): Promise<Response> {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }
  const token = await user.getIdToken();

  const response = await fetch(
    `${API_BASE}/api/v1/users`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });

  return response;
}
