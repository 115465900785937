import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  changeAvailableStatusAPI,
  createNewJobAPI,
  deleteMyJobsAPI,
  getMyJobsAPI,
  updateJobByIdAPI,
} from 'api/recruiterJobs';

interface RecruiterJobsState {
  myJobs: Vacancy[];
  jobPagination: PaginationResponse;
  loading: boolean;
  error: boolean;
  success: boolean;
  errorCreate: boolean;
  successCreate: boolean;
  errorUpdate: boolean;
  successUpdate: boolean;
  errorDelete: boolean;
  successDelete: boolean;
  errorChangeAvailible: boolean;
  successChangeAvailible: boolean;
}

const initialState: RecruiterJobsState = {
  myJobs: [],
  jobPagination: {
    currentPage: 1,
    totalCount: 0,
    totalPages: 0,
  },
  loading: false,
  error: false,
  success: false,
  errorCreate: false,
  successCreate: false,
  errorUpdate: false,
  successUpdate: false,
  errorDelete: false,
  successDelete: false,
  errorChangeAvailible: false,
  successChangeAvailible: false,
};

export const createNewJob = createAsyncThunk(
  'recruiterJobs/createNewJob',
  async (data: CreateVacancy, { rejectWithValue }) => {
    try {
      return await createNewJobAPI(data);
    } catch (error) {
      return rejectWithValue('');
    }
  }
);

export const getMyJobs = createAsyncThunk(
  'recruiterJobs/getMyJobs',
  async (pagination: PaginationRequest, { rejectWithValue }) => {
    try {
      return await getMyJobsAPI(pagination);
    } catch (error) {
      return rejectWithValue('');
    }
  }
);

export const updateJobById = createAsyncThunk(
  'recruiterJobs/updateJobById',
  async (data: VacancyUpdateRequest, { rejectWithValue }) => {
    try {
      return await updateJobByIdAPI(data);
    } catch (error) {
      return rejectWithValue('');
    }
  }
);

export const deleteMyJobs = createAsyncThunk(
  'recruiterJobs/deleteMyJobs',
  async (ids: string[], { rejectWithValue }) => {
    try {
      return await deleteMyJobsAPI(ids);
    } catch (error) {
      return rejectWithValue('');
    }
  }
);

export const changeAvailableStatus = createAsyncThunk(
  'recruiterJobs/changeAvailableStatus',
  async (data: VacancyAvailableStatusChangeResponse, { rejectWithValue }) => {
    try {
      return await changeAvailableStatusAPI(data);
    } catch (error) {
      return rejectWithValue('');
    }
  }
);

const recruiterJobsSlice = createSlice({
  name: 'recruiterJobs',
  initialState,
  reducers: {
    resetStatusForRecruiterJobs: (state) => {
      state.loading = false;
      state.error = false;
      state.success = false;
      state.errorDelete = false;
      state.successDelete = false;
      state.successChangeAvailible = false;
      state.errorChangeAvailible = false;
      state.errorCreate = false;
      state.successCreate = false;
      state.errorUpdate = false;
      state.successUpdate = false;
    },
    setRecruiterJobsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewJob.pending, (state) => {
        state.loading = true;
        state.errorCreate = false;
        state.successCreate = false;
      })
      .addCase(createNewJob.fulfilled,
        (state) => {
          state.loading = false;
          state.errorCreate = false;
          state.successCreate = true;
        })
      .addCase(createNewJob.rejected, (state) => {
        state.loading = false;
        state.errorCreate = true;
        state.successCreate = false;
      })
      .addCase(getMyJobs.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(getMyJobs.fulfilled,
        (state, action: PayloadAction<VacancyResponse>) => {
          state.myJobs = action.payload.myJobs;
          state.jobPagination = {
            currentPage: action.payload.currentPage,
            totalCount: action.payload.totalCount,
            totalPages: action.payload.totalPages,
          };
          state.loading = false;
          state.error = false;
          state.success = true;
        })
      .addCase(getMyJobs.rejected, (state) => {
        state.myJobs = [];
        state.jobPagination = {
          currentPage: 1,
          totalCount: 0,
          totalPages: 0,
        };
        state.loading = false;
        state.error = true;
        state.success = false;
      })
      .addCase(updateJobById.pending, (state) => {
        state.loading = true;
        state.errorUpdate = false;
        state.successUpdate = false;
      })
      .addCase(updateJobById.fulfilled,
        (state) => {
          state.loading = false;
          state.errorUpdate = false;
          state.successUpdate = true;
        })
      .addCase(updateJobById.rejected, (state) => {
        state.loading = false;
        state.errorUpdate = true;
        state.successUpdate = false;
      })
      .addCase(deleteMyJobs.pending, (state) => {
        state.loading = true;
        state.errorDelete = false;
        state.successDelete = false;
      })
      .addCase(deleteMyJobs.fulfilled,
        (state) => {
          state.loading = false;
          state.errorDelete = false;
          state.successDelete = true;
        })
      .addCase(deleteMyJobs.rejected, (state) => {
        state.loading = false;
        state.errorDelete = true;
        state.successDelete = false;
      })
      .addCase(changeAvailableStatus.pending, (state) => {
        state.loading = true;
        state.errorChangeAvailible = false;
        state.successChangeAvailible = false;
      })
      .addCase(changeAvailableStatus.fulfilled,
        (state) => {
          state.loading = false;
          state.errorChangeAvailible = false;
          state.successChangeAvailible = true;
        })
      .addCase(changeAvailableStatus.rejected, (state) => {
        state.loading = false;
        state.errorChangeAvailible = true;
        state.successChangeAvailible = false;
      });
  }
});

export const {
  resetStatusForRecruiterJobs,
  setRecruiterJobsLoading,
} = recruiterJobsSlice.actions;

export default recruiterJobsSlice.reducer;
