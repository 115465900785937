// ===== PUBLIC URLs ===== //

// main public urls //
const getHomePageUrl = (locale: string) => `/${locale}`;
const getLoginPageUrl = (locale: string) => `/${locale}/login`;
const getSignUpAsRecruiterPageUrl = (locale: string) => `/${locale}/employers`;
const getRoommatesInfoPageUrl = (locale: string) => `/${locale}/info-roommates`;
const getAccommodationsInfoPageUrl = (locale: string) => `/${locale}/info-accommodations`;
const getMentorshipPageUrl = (locale: string) => `/${locale}/info-mentorship`;
// footer public urls //
const getAboutUsPageUrl = (locale: string) => `/${locale}/about-us`;
const getContactUsPageUrl = (locale: string) => `/${locale}/contact-us`;
const getDonationsPageUrl = (locale: string) => `/${locale}/donations`;
const getUsefulLinksPageUrl = (locale: string) => `/${locale}/useful-links`;
const getPressPageUrl = (locale: string) => `/${locale}/press`;
const getFAQPageUrl = (locale: string) => `/${locale}/faq`;
const getSafetyTipsPageUrl = (locale: string) => `/${locale}/safety-tips`;
// footer PrivacyPolicy&TermsOfUse public urls //
const getResponsibleDisclosurePolicyPageUrl = (locale: string) =>
  `/${locale}/responsible-disclosure-policy`;
const getCookiesPageUrl = (locale: string) => `/${locale}/cookies`;
const getPrivacyPolicyRefugeesPageUrl = (locale: string) => `/${locale}/privacy-policy-refugees`;
const getTermsOfUseRefugeesPageUrl = (locale: string) => `/${locale}/terms-and-conditions-refugees`;
const getPrivacyPolicyHostsPageUrl = (locale: string) => `/${locale}/privacy-policy-host`;
const getTermsOfUseHostsPageUrl = (locale: string) => `/${locale}/terms-of-use-hosts`;
// footer Jobs public urls //
const getRemoteJobsInfoPageUrl = (locale: string) => `/${locale}/remote-jobs-for-ukrainians`;
const getRemoteJobGermanyPageUrl =
  (locale: string) => `/${locale}/jobs-articles/find-a-job-in-germany-jobs-for-ukrainians`;
const getRemoteJobSpainPageUrl =
  (locale: string) => `/${locale}/jobs-articles/find-a-job-in-spain-jobs-for-ukrainians`;
const getRemoteJobFrancePageUrl =
  (locale: string) => `/${locale}/jobs-articles/find-a-job-in-france-jobs-for-ukrainians`;
const getRemoteJobEuropePageUrl =
  (locale: string) => `/${locale}/jobs-articles/find-a-job-in-europe-jobs-for-ukrainians`;
// footer Housing public urls //
const getFreeHousingForRefugeesPageUrl = (locale: string) => `/${locale}/free-housing-for-refugees`;
const getColivingForRefugeesPageUrl = (locale: string) => `/${locale}/coliving-for-refugees`;
//Mentorship public urls
const getMentorshipInfoHelperPageUrl = (locale: string) => `/${locale}/info-helper-mentorship`;
const getMentorshipInfoRefugeePageUrl = (locale: string) => `/${locale}/info-refugee-mentorship`;
//Stories public urls
const getStoriesTetianaUrl = (locale: string) => `/${locale}/stories/tetjana`;
// ===== PRIVATE URLs ===== //
const getDashboardPageUrl = (locale: string) => `/${locale}/dashboard`;
const getProfileSettingsPageUrl = (locale: string) => `/${locale}/profile`;
const getAccommodationCreatePageUrl = (locale: string) => `/${locale}/accommodation-create`;
const getAccommodationsBrowsingPageUrl = (locale: string) => `/${locale}/accommodations`;
const getMentorBrowsingPageUrl = (locale: string) => `/${locale}/mentors`;
const getAccommodationReviewPageUrl =
  (locale: string) => `${getAccommodationsBrowsingPageUrl(locale)}/:id`;
const getRoommatesBrowsingPageUrl = (locale: string) => `/${locale}/roommates`;
const getRoommateReviewPageUrl =
  (locale: string) => `${getRoommatesBrowsingPageUrl(locale)}/:id`;
const getJobsBrowsingPageUrl = (locale: string) => `/${locale}/jobs`;
//TODO for next version
const getJobReviewPageUrl = (locale: string) => `${getJobsBrowsingPageUrl(locale)}/:id`;

//== RECRUITER PAGE ==//
const getManageJobOffersPageUrl = (locale: string) => `/${locale}/my-job-offers`;

export const appUrls = {
  getHomePageUrl,
  getLoginPageUrl,
  getSignUpAsRecruiterPageUrl,
  getRoommatesInfoPageUrl,
  getAccommodationsInfoPageUrl,
  getMentorshipPageUrl,
  getDonationsPageUrl,
  getAboutUsPageUrl,
  getContactUsPageUrl,
  getUsefulLinksPageUrl,
  getPressPageUrl,
  getFAQPageUrl,
  getSafetyTipsPageUrl,
  getResponsibleDisclosurePolicyPageUrl,
  getCookiesPageUrl,
  getPrivacyPolicyRefugeesPageUrl,
  getTermsOfUseRefugeesPageUrl,
  getPrivacyPolicyHostsPageUrl,
  getTermsOfUseHostsPageUrl,
  getRemoteJobGermanyPageUrl,
  getRemoteJobSpainPageUrl,
  getRemoteJobFrancePageUrl,
  getRemoteJobEuropePageUrl,
  getRemoteJobsInfoPageUrl,
  getFreeHousingForRefugeesPageUrl,
  getColivingForRefugeesPageUrl,
  getMentorshipInfoHelperPageUrl,
  getMentorshipInfoRefugeePageUrl,
  getStoriesTetianaUrl,

  getDashboardPageUrl,
  getProfileSettingsPageUrl,
  getAccommodationCreatePageUrl,
  getAccommodationsBrowsingPageUrl,
  getAccommodationReviewPageUrl,
  getRoommatesBrowsingPageUrl,
  getRoommateReviewPageUrl,
  getJobsBrowsingPageUrl,
  getJobReviewPageUrl,
  getManageJobOffersPageUrl,
  getMentorBrowsingPageUrl,
};
