import { RootState, useAppDispatch } from '../store';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Suspense, useEffect, useRef, useState } from 'react';
import {
  allRoutes, appUrls, availableLocales, defaultLocale, redirectedAppRoutes,
} from 'router/constants';
import { AnimatePresence } from 'framer-motion';
import App from 'App';
import { LocaleContext } from './constants/localeContext';
import PageLoader from 'components/PageLoader';
import { fetchUserDetails } from 'store/slices/userSlice';
import { initializeAppAuth } from '../firebase';
import { lazyWithRetry } from 'utils/lazyWithRetry';
import { useAuthUserStatus } from 'hooks/useAuthUserStatus';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const NotFound = lazyWithRetry(() => import('pages/errors/NotFound'), 'NotFound');

// Initialize Firebase
initializeAppAuth();

function AppRouter() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  //set body direction
  document.body.dir = i18n.dir(i18n.language);
  const { pathname, search, hash } = useLocation();
  const pathnameLocale = pathname.substring(1, 3).toLowerCase();
  const { isLoading, isAuthenticated, isEmailVerified } = useAuthUserStatus();
  const { user } = useSelector((state: RootState) => state.auth);
  const { data } = useSelector((state: RootState) => state.user);
  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true);
  const [locale, setLocale] = useState<string>(defaultLocale);
  const loaderTimerRef = useRef<NodeJS.Timeout>();
  const isLoginPageView = window.location.href
    .split('?')[0].split('/').pop() === 'login';
  const isEmployersPageView = window.location.href
    .split('?')[0].split('/').pop() === 'employers';

  useEffect(() => {
    if (user && !data && !isLoginPageView && !isEmployersPageView) {
      dispatch(fetchUserDetails(user.uid));
    }
  }, [data, dispatch, user]);

  useEffect(() => {
    loaderTimerRef.current = setTimeout(() => {
      setIsLoadingPage(false);
      clearTimeout(loaderTimerRef.current);
    }, 300);
  }, []);

  useEffect(() => {
    if (availableLocales.includes(pathnameLocale)) {
      updateLocale(pathnameLocale);
    } else if (pathname === '/') {
      updateLocale(defaultLocale);
    }
  }, [pathname]);

  useEffect(() => {
    let lang = defaultLocale;

    if (availableLocales.includes(pathnameLocale)) {
      lang = pathnameLocale;
      setLanguageHandler(lang);
    } else if (pathname === '/') {
      setLanguageHandler(lang);
    }
  }, [locale]);

  const setLanguageHandler = (lang: string) => {
    //set language attribute on HTML element
    document.documentElement.setAttribute('lang', lang);
    switch (lang) {
    case 'en':
      return i18n.changeLanguage('en');
    case 'uk':
      return i18n.changeLanguage('uk');
    case 'fr':
      return i18n.changeLanguage('fr');
    case 'es':
      return i18n.changeLanguage('es');
    case 'it':
      return i18n.changeLanguage('it');
    default:
      return i18n.changeLanguage('en');
    }
  };

  const updateLocale = (newLocale: string) => {
    const newPath = `/${newLocale}` + pathname.substring(3);

    if (locale !== newLocale) {
      if (newPath === `/${newLocale}/` || newPath === `/${newLocale}` || pathname === '/') {
        navigate(appUrls.getHomePageUrl(newLocale));
      } else {
        navigate(`${newPath}${hash}${search}`);
      }
      setLocale(newLocale);
    } else if (newPath === `/${newLocale}/` || newPath === `/${newLocale}` || pathname === '/') {
      if (isAuthenticated && isEmailVerified) {
        navigate(appUrls.getDashboardPageUrl(newLocale));
      } else {
        navigate(appUrls.getHomePageUrl(newLocale));
      }
    }
  };

  const renderRouteWithChildren = (routes: AppRoute[]) => {
    return routes.map((route: AppRoute, index: number) => (
      <Route key={index} path={route.path(locale)} element={route.element || route.element(locale)}>
        {route.children && renderRouteWithChildren(route.children)}
      </Route>
    ));
  };

  if (isLoading || isLoadingPage) {
    return (
      <PageLoader />
    );
  }

  return (
    <AnimatePresence initial={false} mode="wait">
      <LocaleContext.Provider value={{ locale, setLocale: updateLocale }}>
        <Suspense fallback={<PageLoader />}>
          <Routes>
            <Route path={`/${locale}`} element={<App />}>
              {renderRouteWithChildren(allRoutes)}
            </Route>
            {redirectedAppRoutes(locale)}
            <Route path="*" element={<NotFound />} />
            <Route path={`/${locale}/*`} element={<NotFound />} />
          </Routes>
        </Suspense>
      </LocaleContext.Provider>
    </AnimatePresence>
  );
}

export default AppRouter;
