import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import moment from 'moment';

export const useAuthUserStatus = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoading(false);

      if (user) {
        setIsAuthenticated(true);
        setIsEmailVerified(!!user.emailVerified ||
          moment(user.metadata.creationTime, 'ddd, DD MMM YYYY HH:mm:ss [GMT]')
            .isBefore(moment('11 July 2023 10:16:44 +0300', 'DD MMMM YYYY HH:mm:ss Z')));
      } else {
        setIsAuthenticated(false);
        setIsEmailVerified(false);
      }
    });
    return () => unsubscribe();
  }, []);

  return { isLoading, isAuthenticated, isEmailVerified };
};
