import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

interface PageLoaderProps {
  classes?: string;
}

function PageLoader({ classes = 'h-screen' }: PageLoaderProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'pageLoader' });

  const tip = (
    <p className="absolute top-4 translate-x-[-47%]">
      {t('loading')}
    </p>
  );

  return (
    <div className={'flex w-full items-center justify-center p-4' +
      ` ${classes}`}>
      <Spin size="large" tip={tip}><p /></Spin>
    </div>
  );
}

export default PageLoader;
