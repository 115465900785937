import { getAuth } from 'firebase/auth';

const API_BASE = process.env.REACT_APP_API_BASE_URL;

// for browsing roommates as a refugee
export async function getRoommatesAPI(data: RoommateProfileRequest)
  : Promise<RoommateProfileResponse | void> {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken();
  const raw = JSON.stringify({ ...data });
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  const response = await fetch(
    `${API_BASE}/api/v1/users/roommate-profile/search`,
    requestOptions,
  )
    .then((response: Response) => response?.json() || [])
    .then((result: RoommateProfileResponse) => result)
    .catch(error => error);

  return response;
}

// for browsing roommates as a refugee
export async function getRoommatesPreviewAllAPI()
  : Promise<{ lastRoommateProfiles: RoommateProfilePreview[] }> {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken();
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  };

  const response = await fetch(
    `${API_BASE}/api/v1/users/roommate-profile/preview-all`,
    requestOptions,
  );

  return !response.ok ? Promise.reject(response.status) : await response.json();
}

// for browsing roommates on the map as a refugee
export async function getRoommatesForMapAPI(
  filters: Partial<RoommateFilters>): Promise<RoommateProfileMapResponse> {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken();
  const raw = JSON.stringify({ filters });
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  const response = await fetch(
    `${API_BASE}/api/v1/users/roommate-profile/search/map`,
    requestOptions,
  ).then((response: Response) => response?.json() || {
    allRoommateProfiles: [],
    totalCount: 0,
  })
    .then((result: RoommateProfileMapResponse) => result);

  return response;
}

// for browsing roommates as a refugee
export async function getRoommatesByIdAPI(id: string): Promise<FullRoommateProfile | null> {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken();
  const response = await fetch(
    `${API_BASE}/api/v1/users/${id}/roommate-profile`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then((response: Response) => response?.json() || [])
    .then((result: FullRoommateProfile) => result)
    .catch(error => error); //TODO: smth went wrong

  return response || null;
}

// for creating profile (first time) as a refugee
export async function createRoommateAPI(data: UserRoommateProfile): Promise<boolean> {
  const url = `${API_BASE}/api/v1/users/roommate-profile`;
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User not logged in');
  }
  const token = await user.getIdToken();

  const response = await fetch(`${url}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data),
  });

  return response.ok;
}

// for updating roommate profile as a refugee
export async function updateRoommateProfileAPI(data: UserRoommateProfile): Promise<Response> {
  const url = `${API_BASE}/api/v1/users/roommate-profile/update`;
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) {
    throw new Error('User not logged in');
  }
  const token = await user.getIdToken();

  const response = await fetch(`${url}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data),
  });

  return response;
}

// for updating roommate profile as a refugee
export async function onChangeAvailableRoommateProfileAPI(enabled: boolean): Promise<Response> {
  const api = `${API_BASE}/api/v1/users/roommate-profile/` +
    `activate?isActive=${enabled}`;
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken();

  const response = await fetch(api, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });

  return response;
}

// for deleting roommate profile as a refugee
export async function deleteRoommateProfileAPI(): Promise<Response> {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not logged in');
  }

  const token = await user.getIdToken();

  const response = await fetch(
    `${API_BASE}/api/v1/users/roommate-profile/delete`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });

  return response;
}
