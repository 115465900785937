import { Dispatch, SetStateAction } from 'react';

interface NumberButtonProps {
  number: number;
  setNumber: Dispatch<SetStateAction<number>>;
  maxNumber?: number;
}

function NumberButton({ number, setNumber, maxNumber }: NumberButtonProps) {
  const handleDecrement = () => {
    if (number >= 1) {
      setNumber(number - 1);
    }
  };

  const handleIncrement = () => {
    if (maxNumber && number === maxNumber) return;
    setNumber(number + 1);
  };

  return (
    <div
      className={'flex items-center justify-between w-[106px]' +
        ' max-h-40 bg-blue-600 rounded-2xl self-center'}>
      <button
        type="button"
        onClick={handleDecrement}
        className="rounded-full bg-blue-700 px-4 py-2 font-bold text-white focus:outline-none"
        data-testid="DecreaseElmt"
      >
        -
      </button>
      <span className="px-1 font-bold text-black">{number}</span>
      <button
        type="button"
        onClick={handleIncrement}
        className="rounded-full bg-blue-700 px-4 py-2 font-bold text-white focus:outline-none"
        data-testid="IncreaseElmt"
      >
        +
      </button>
    </div>
  );
}

export default NumberButton;
