import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { FaSpinner } from 'react-icons/fa';

type PrimaryButtonProps = {
  classNames?: string;
  text: string;
  loader?: boolean;
  reverse?: boolean;
  datatestid?: string;
  icon?: JSX.Element;
  mobileTextHidden?: boolean;
} & DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

function PrimaryButton({
  classNames,
  text,
  loader,
  reverse,
  datatestid,
  icon,
  mobileTextHidden,
  ...props
}: PrimaryButtonProps) {
  const styles = reverse
    ? 'font-semibold bg-white text-blue-700 hover:bg-white hover:text-blue-800' +
    ' active:bg-white active:text-blue-800 disabled:bg-white disabled:text-blue-300'
    : 'font-bold bg-blue-700 text-white hover:bg-blue-800 active:bg-blue-800 disabled:bg-grey-600';

  return (
    <button
      {...props}
      className={
        'h-fit rounded-[50px] px-6 py-2 text-lg flex flex-row items-center justify-center gap-3' +
        ' transition-colors duration-300 disabled:cursor-not-allowed disabled:bg-grey-600' +
        ` ${classNames ? classNames : ''} ${styles}`}
      data-testid={datatestid}
    >
      {loader ? <FaSpinner className="mx-auto h-7 w-7 animate-spin" /> :
        mobileTextHidden ? <p className="hidden desktop:block">{text}</p> : text}
      {!loader && icon}
    </button>
  );
}

export default PrimaryButton;
