import { Modal } from 'antd';
import { ReactNode } from 'react';
import { useScreenDimensions } from 'hooks/useScreenDimensions';
import './MainModalContainer.css';

interface MainModalContainerProps {
  open: boolean;
  modalHeader: ReactNode;
  modalFooter: ReactNode;
  children: ReactNode;
  fullScreen?: boolean;
  fitContent?: boolean;
  limitedHeight?: boolean;
}

function MainModalContainer({
  open,
  modalHeader,
  modalFooter,
  children,
  fullScreen,
  fitContent,
  limitedHeight,
}: MainModalContainerProps) {
  const { screenWidth } = useScreenDimensions();

  const getWidth = () => {
    if (fullScreen) return 'calc(100% - 32px)';
    if (fitContent) return 'fit-content';
    return screenWidth > 768 ? '85%' : '100%';
  };

  const getHeight = () => {
    if (fullScreen) return 'calc(100% - 4px)';
    if (fitContent) return '85%';
    if (limitedHeight && screenWidth > 768) return '300px';
    if (limitedHeight && screenWidth <= 768) return '430px';
    return 'calc(100% - 64px)';
  };

  const getTop = () => {
    if (fullScreen) return 2;
    if (fitContent) return 64;
    if (limitedHeight && screenWidth > 768) return '50%';
    if (limitedHeight && screenWidth <= 768) return '30%';
    return 32;
  };

  return (
    <Modal
      open={open}
      title={modalHeader}
      closable={false}
      footer={modalFooter}
      closeIcon={null}
      rootClassName={'main-modal-container' + (fullScreen ? ' full-screen' : '')}
      width={getWidth()}
      style={{
        top: getTop(),
        height: getHeight(),
        maxWidth: '98%',
        overflow: 'hidden',
        padding: 0,
        borderRadius: 16,
        margin: fullScreen ? '0 0 0 auto' : '0 auto',
      }}
    >
      {children}
    </Modal>
  );
}

export default MainModalContainer;
