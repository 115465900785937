import { useTranslation } from 'react-i18next';

export function useModalTranslations() {
  const { t } = useTranslation('translation', { keyPrefix: 'modal' });

  return {
    profileCompleteYourProfile: t('profileFilling.completeYourProfile'),
    profileCompleteYourProfileBeforeRoommate: t(
      'profileFilling.completeYourProfileBeforeRoommate'),
    profileSelectGender: t('profileFilling.selectGender'),
    profileGenderTitle: t('profileFilling.selectGenderTitle'),
    profileLanguagesTitle: t('profileFilling.selectLanguageTitle'),
    profileYourAge: t('profileFilling.yourAge'),
    profileSelectAge: t('profileFilling.selectAge'),
    profileAddPhotoToProfile: t('profileFilling.addPhotoToProfile'),
    profileOptional: t('profileFilling.optional'),
    profileCancelBtn: t('profileFilling.cancelButton'),

    deleteAccountTitle: t('deleteAccount.title'),
    deleteAccountDescription2: t('deleteAccount.description2'),
    deleteAccountDeleteBtn: t('deleteAccount.deleteButton'),
    deleteAccountCancelBtn: t('deleteAccount.cancelButton'),
    deleteAccountPassword: t('deleteAccount.password'),
    deleteAccountInvalidPassword: t('deleteAccount.invalidPassword'),

    changePasswordTitle: t('changePassword.title'),
    changePasswordDescription: t('changePassword.description'),
    changePasswordCurrentPassword: t('changePassword.currentPassword'),
    changePasswordNewPassword: t('changePassword.newPassword'),
    changePasswordConfirmNewPassword: t('changePassword.confirmNewPassword'),
    changePasswordChangeBtn: t('changePassword.changeButton'),
    passwordChanged: t('changePassword.passwordChanged'),
    loginBeforeChangePassword: t('changePassword.loginBeforeChangePassword'),
    wrongPassword: t('changePassword.wrongPassword'),

    cancelBtn: t('sendContactRequest.cancelBtn'),
    sendBtn: t('sendContactRequest.sendBtn'),
    errorMessage: t('sendContactRequest.errorMessage'),
    placeholderAcc: t('sendContactRequest.placeholderAcc'),
    placeholderRoo: t('sendContactRequest.placeholderRoo'),
    descriptionSendContactRequest: t('sendContactRequest.description'),

    save: t('accommodationFillingFooter.save'),
    continue: t('accommodationFillingFooter.continue'),

    fillYourAccommodation: t('accommodationFillingHeader.fillYourAccommodation'),

    anyoneWithYou: t('firstStep.anyoneWithYou'),
    addFamilyMembers: t('firstStep.addFamilyMembers'),
    pleaseDontCount: t('firstStep.pleaseDontCount'),

    uploadPhotos: t('fourthStep.uploadPhotos'),

    howManyPets: t('secondStep.howManyPets'),
    addPets: t('secondStep.addPets'),

    addInfoAboutYou: t('thirdStep.addInfoAboutYou'),
    infoWillbeSaved: t('thirdStep.infoWillbeSaved'),
    whatCanYouTell: t('thirdStep.whatCanYouTell'),

    iConfirm: t('confirmFillingModal.iConfirm'),
    allInfoTrue: t('confirmFillingModal.allInfoTrue'),
    iShareData: t('confirmFillingModal.iShareData'),

    savingInfo: t('accommodationFilling.savingInfo'),
    sureCancel: t('accommodationFilling.sureCancel'),
    info: t('accommodationFilling.info'),
    cancelDontSave: t('accommodationFilling.cancelDontSave'),
    continueFilling: t('accommodationFilling.continueFilling'),

    hasAccommodation: t('roommatesFilling.hasAccommodation'),
    hasNotAccommodation: t('roommatesFilling.hasNotAccommodation'),
    savingInfoRoommate: t('roommatesFilling.savingInfo'),
    sureCancelRoommate: t('roommatesFilling.sureCancel'),
    infoRoommate: t('roommatesFilling.info'),
    cancelDontSaveRoommate: t('roommatesFilling.cancelDontSave'),
    continueFillingRoommate: t('roommatesFilling.continueFilling'),

    howLong: t('roommatesFillingFirstStep.howLong'),
    country: t('roommatesFillingFirstStep.country'),
    city: t('roommatesFillingFirstStep.city'),

    howManyMoney: t('roommatesFillingFourthStep.howManyMoney'),
    validPrice: t('roommatesFillingFourthStep.validPrice'),
    perPersonMonthly: t('roommatesFillingFourthStep.perPersonMonthly'),

    saving: t('roommatesFillingFooter.saving'),
    saveError: t('roommatesFillingFooter.saveError'),
    savedOk: t('roommatesFillingFooter.savedOk'),
    saveRoommate: t('roommatesFillingFooter.saveRoommate'),
    continueRoommate: t('roommatesFillingFooter.continueRoommate'),

    completeToContinue: t('roommatesFillingHeader.completeToContinue'),

    roommateSpecConditions: t('roommatesFillingSecondStep.roommateSpecConditions'),

    helpToKnowEach: t('roommatesFillingThirdStep.helpToKnowEach'),
    tellUs: t('roommatesFillingThirdStep.tellUs'),
    describePreferences: t('roommatesFillingThirdStep.describePreferences'),
    atLeast50: t('roommatesFillingThirdStep.atLeast50'),

    genderDoesntMatch: t('genderMatch.genderDoesntMatch'),
    notPossible: t('genderMatch.notPossible'),
    useThe: t('genderMatch.useThe'),
    filters: t('genderMatch.filters'),
    toSelectCorrect: t('genderMatch.toSelectCorrect'),
    backBrowsing: t('genderMatch.backBrowsing'),

    numberOfGuestsDoesntMatch: t('numberOfGuestsMatch.numberOfGuestsDoesntMatch'),
    notPossibleGuests: t('numberOfGuestsMatch.notPossible'),
    useTheGuests: t('numberOfGuestsMatch.useThe'),
    filtersGuests: t('numberOfGuestsMatch.filters'),
    toSelectCorrectGuests: t('numberOfGuestsMatch.toSelectCorrect'),
    backBrowsingGuests: t('numberOfGuestsMatch.backBrowsing'),

    petsDoesntMatch: t('petsMatch.petsDoesntMatch'),
    notPossiblePets: t('petsMatch.notPossible'),
    useThePets: t('petsMatch.useThe'),
    filtersPets: t('petsMatch.filters'),
    toSelectCorrectPets: t('petsMatch.toSelectCorrect'),
    backBrowsingPets: t('petsMatch.backBrowsing'),

    childrenDoesntMatch: t('childrenMatch.childrenDoesntMatch'),
    notPossibleChildren: t('childrenMatch.notPossible'),
    useTheChildren: t('childrenMatch.useThe'),
    filtersChildren: t('childrenMatch.filters'),
    toSelectCorrectChildren: t('childrenMatch.toSelectCorrect'),
    backBrowsingChildren: t('childrenMatch.backBrowsing'),

    gotoJobList: t('jobList.gotoJobList'),
    gotoPolicyList: t('policyList.gotoPolicyList'),

    mainCompanyInfoBlockDescription: t('companyInfoBlock.mainCompanyInfoBlockDescription'),
    companyName: t('companyInfoBlock.companyName'),
    companyNamePlaceholder: t('companyInfoBlock.companyNamePlaceholder'),
    companyUrl: t('companyInfoBlock.companyUrl'),
    companyUrlPlaceholder: t('companyInfoBlock.companyUrlPlaceholder'),
    companyDescription: t('companyInfoBlock.companyDescription'),
    companyDescriptionPlaceholder: t('companyInfoBlock.companyDescriptionPlaceholder'),
    companyLogo: t('companyInfoBlock.companyLogo'),
    companyLogoPlaceholder: t('companyInfoBlock.companyLogoPlaceholder'),

    jobTitle: t('vacancyForm.jobTitle'),
    jobTitlePlaceholder: t('vacancyForm.jobTitlePlaceholder'),
    industry: t('vacancyForm.industry'),
    industryPlaceholder: t('vacancyForm.industryPlaceholder'),
    vacancyDescription: t('vacancyForm.vacancyDescription'),
    vacancyDescriptionPlaceholder: t('vacancyForm.vacancyDescriptionPlaceholder'),
    jobLocationTitle: t('vacancyForm.jobLocationTitle'),
    remoteCountiresPlaceholder: t('vacancyForm.remoteCountiresPlaceholder'),
    employmentTypeTitle: t('vacancyForm.employmentTypeTitle'),
    employmentTypePlaceholder: t('vacancyForm.employmentTypePlaceholder'),
    applicationTypeTitle: t('vacancyForm.applicationTypeTitle'),
    applicationTypePlaceholder: t('vacancyForm.applicationTypePlaceholder'),
    salaryTitle: t('vacancyForm.salaryTitle'),
    salaryNotIndicated: t('vacancyForm.salaryNotIndicated'),
    salaryIndicated: t('vacancyForm.salaryIndicated'),
    experienceLevelTitle: t('vacancyForm.experienceLevelTitle'),
    experienceLevelPlaceholder: t('vacancyForm.experienceLevelPlaceholder'),
    languagesRequiredTitle: t('vacancyForm.languagesRequiredTitle'),
    languagesRequiredPlaceholder: t('vacancyForm.languagesRequiredPlaceholder'),
    postJobOffer: t('vacancyForm.postJobOffer'),
    jobOfferCreatorTitle: t('vacancyForm.jobOfferCreatorTitle'),
    perMonth: t('vacancyForm.perMonth'),
    from: t('vacancyForm.from'),
    any: t('vacancyForm.any'),
    postJobOfferLoading: t('vacancyForm.postJobOfferLoading'),
    postJobOfferSuccess: t('vacancyForm.postJobOfferSuccess'),
    postJobOfferSuccessDescription: t('vacancyForm.postJobOfferSuccessDescription'),
    postJobOfferError: t('vacancyForm.postJobOfferError'),
    createAnotherJobOffer: t('vacancyForm.createAnotherJobOffer'),
    close: t('vacancyForm.close'),
    updateJobOfferLoading: t('vacancyForm.updateJobOfferLoading'),
    updateJobOffer: t('vacancyForm.updateJobOffer'),
    updateJobOfferError: t('vacancyForm.updateJobOfferError'),
    updateJobOfferSuccess: t('vacancyForm.updateJobOfferSuccess'),

    policyDescription: t('cookie.policyDescription'),
    policyDescriptionPlaceholder: t('cookie.policyDescriptionPlaceholder'),
    policyDescriptionSecond: t('cookie.policyDescriptionSecond'),
    policyAccept: t('cookie.policyAccept'),
    policyDecline: t('cookie.policyDecline'),

    mentorAccountActive: t('mentorFilling.mentorAccountActive'),
    mentorYourPhoto: t('mentorFilling.yourPhoto'),
    mentorWhichQuestion: t('mentorFilling.whichQuestion'),
    mentorWhichQuestionPlaceholder: t('mentorFilling.whichQuestionPlaceholder'),
    mentorTextAreaPlaceholder: t('mentorFilling.textAreaPlaceholder'),
    mentorAtLeast30: t('mentorFilling.atLeast30'),
    
    mentorSuccess: t('mentorFilling.success'),
    mentorSuccessDescription: t('mentorFilling.successDescription'),
    mentorEmailNotification: t('mentorFilling.emailNotification'),


  };
}
